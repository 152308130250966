import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import CategorySlider from "../standard/CategorySlider";
// import MainSliderBullets from "../standard/MainSliderBullets";

import Image from "../standard/Image";
import { AngleDownIcon } from "../standard/Icons";

const usePortfolio = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------------------\\
  //*- Get Images from the folder -*\\
  //--------------------------------\\
  function importAllImages(r) {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAllImages(
    require.context(
      "../../images/portfolio",
      false,
      /\.(png|jpe?g|svg|webp|mp4)$/
    )
  );

  //---------------------\\
  //*- Data for Slides -*\\
  //---------------------\\
  const portfolioItems = {
    web: {
      1: {
        title: "Almital E-Commerce Website",
        body: "Almital Furniture Accessories. Our company was founded after years of experience and knowledge gained in international corporate firms in the furniture accessories sector.",
        link: "https://almital.com.tr/",
        image: images["web_react.png"],
        program: "react",
      },
      2: {
        title: "CentriService Website",
        body: "",
        link: "https://centriservice.com",
        image: images["web_react.png"],
        program: "react",
      },
      3: {
        title: "Onur Karagüler Portfolio",
        body: "GoogleMapsSearch is an desktop application that extracts company information from Google Maps.",
        link: "https://onurkaraguler.com/",
        image: images["web_react.png"],
        program: "react",
      },
      4: {
        title: "Termosim Website",
        body: "Termosim - Thermal & Cooling Systems Engineering Solutions was founded by two partners who have worked for many years in military vehicle projects and cooling industry with the aim of producing thermal solutions for Defense industry and Cooling systems with TUBITAK support in 2022.",
        link: "http://termosim.com.tr",
        image: images["web_js.png"],
        program: "react",
      },
      5: {
        title: "Almital Website",
        body: "Almital Furniture Accessories. Our company was founded after years of experience and knowledge gained in international corporate firms in the furniture accessories sector.",
        link: "http://almital.com/",
        image: images["web_js.png"],
        program: "react",
      },
    },
    data: {
      1: {
        title: "Customer Churn Analysis",
        body: "This data set contains details of a bank's customers and the target variable is a binary variable reflecting the fact whether the customer left the bank (closed his account) or not.",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Classification/Customer_Churn_Analysis",
        image: images["customer_churn.png"],
        program: "classification",
      },
      2: {
        title: "EMP Case Study",
        body: "At EMP we deal daily with a great amount of data from different sources, produced by the customer journey. In this challenge, we will focus on the sales data, produced by customers visiting and purchasing items from our web shop (emp.de in Germany).",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Classification/EMP",
        image: images["emp.png"],
        program: "classification",
      },
      3: {
        title: "Predictive Maintenance",
        body: "Since real predictive maintenance datasets are generally difficult to obtain and in particular difficult to publish, we present and provide a synthetic dataset that reflects real predictive maintenance encountered in industry to the best of our knowledge.",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Classification/Predictive_Maintenance_Dataset",
        image: images["predictive_maintenance.png"],
        program: "classification",
      },
      4: {
        title: "Titanic",
        body: "The purpose of the study is to build a predictive model that answers the question: “what sorts of people were more likely to survive?” using passenger data (ie name, age, gender, socio-economic class, etc).",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Classification/Titanic%20-%20Machine%20Learning",
        image: images["titanic.png"],
        program: "classification",
      },
      5: {
        title: "Developer Salary Prediction",
        body: "The purpose of the study is to predict of annual salaries ($) of professional software developers who know Python. Data from the Developer Survey conducted by Stack Overflow in 2020 and attended by 65,000 people were used in this analysis.",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Regression/Developer_Salary_Prediction",
        image: images["developer_salary_prediction.png"],
        program: "regression",
      },
      6: {
        title: "House Prices",
        body: "With 79 explanatory variables describing (almost) every aspect of residential homes in Ames, Iowa, the goal is to predict the sales price of each home.",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Regression/House_Prices",
        image: images["house_prices.png"],
        program: "regression",
      },
      7: {
        title: "Steel Industry Energy...",
        body: "The information gathered is from the DAEWOO Steel Co. Ltd in Gwangyang, South Korea. The information on energy consumption of the industry is stored on the website of the Korea Electric Power Corporation (pccs.kepco.go.kr), and the perspectives on daily, monthly, and annual data are calculated and shown.",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Regression/Steel_Industry_Energy_Consumption",
        image: images["steel_industry_energy.png"],
        program: "regression",
      },
      8: {
        title: "Customer Churn Clustering",
        body: "This data set contains details of a bank's customers and the target variable is a binary variable reflecting the fact whether the customer left the bank (closed his account) or not.",
        link: "https://github.com/OnurKaraguler/Machine_Learning_Studies/tree/main/Clustering/Customer_Churn_Analysis",
        image: images["customer_churn_cluster.png"],
        program: "clustering",
      },
      9: {
        title: "Adult Income",
        body: "An individual’s annual income results from various factors. Intuitively, it is influenced by the individual’s education level, age, gender, occupation, and etc. We can explore the possibility in predicting income level based on the individual’s personal information.",
        link: "https://github.com/OnurKaraguler/Pyspark_Studies/tree/main/Adult_Income_Dataset",
        image: images["adult_income.png"],
        program: "pySpark",
      },
      10: {
        title: "Bank Marketing",
        body: "Find the best strategies to improve for the next marketing campaign. How can the financial institution have a greater effectiveness for future marketing campaigns? In order to answer this, we have to analyze the last marketing campaign the bank performed and identify the patterns that will help us find conclusions in order to develop future strategies.",
        link: "https://github.com/OnurKaraguler/Pyspark_Studies/tree/main/Bank_Marketing_Spark",
        image: images["bank_marketing.png"],
        program: "pySpark",
      },
      11: {
        title: "Customer Churn Analysis",
        body: "This data set contains details of a bank's customers and the target variable is a binary variable reflecting the fact whether the customer left the bank (closed his account) or not.",
        link: "https://github.com/OnurKaraguler/Pyspark_Studies/tree/main/Customer_Churn_Analysis_Pyspark",
        image: images["customer_churn_pyspark.png"],
        program: "pySpark",
      },
      12: {
        title: "Rock Paper Scissors",
        body: "Rock Paper Scissors contains images from various hands, from different races, ages, and genders, posed into Rock / Paper or Scissors and labeled as such. Pictures use a plain white background. Each image is 300×300 pixels in 24-bit color.",
        link: "https://github.com/OnurKaraguler/Deep_Learning_Studies/tree/main/Rock_Paper_Scissors",
        image: images["rock_paper_scissors_ai.png"],
        program: "ANNCNN",
      },
      13: {
        title: "Customer Churn Analysis",
        body: "This data set contains details of a bank's customers and the target variable is a binary variable reflecting the fact whether the customer left the bank (closed his account) or not.",
        link: "https://github.com/OnurKaraguler/Deep_Learning_Studies/tree/main/Customer_Churn_Analysis",
        image: images["customer_churn_ai.png"],
        program: "ANNCNN",
      },
      14: {
        title: "Titanic",
        body: "The purpose of the study is to build a predictive model that answers the question: “what sorts of people were more likely to survive?” using passenger data (ie name, age, gender, socio-economic class, etc).",
        link: "https://github.com/OnurKaraguler/Deep_Learning_Studies/tree/main/Titanic",
        image: images["titanic_ai.png"],
        program: "ANNCNN",
      },
    },
    scrap: {
      1: {
        title: "GoogleMaps Search App",
        body: "GoogleMapsSearch is an desktop application that extracts company information from Google Maps.",
        link: "https://www.youtube.com/watch?v=BrVByQ4uCxk&list=PLiY6ftw6oRV5NamPwNy1dgafOrn3s6tEy",
        image: images["web_scraping_and_app.png"], // "images/portfolio/web_scraping_and_app.png"
      },
      2: {
        title: "Big Time Open Market",
        body: "Big Time Open Market is a website, where you can trade Big Time NFTs with other players. Extract serial numbers and prices from https://nft.bigtime.gg/explore",
        link: "https://www.youtube.com/watch?v=Br_yiaUwT6w&list=PLiY6ftw6oRV5NamPwNy1dgafOrn3s6tEy&index=3",
        image: images["web_scraping.png"], // "images/portfolio/web_scraping.png"
      },
      3: {
        title: "Website with CAPTCHA",
        body: "Web scraping from a website with CAPTCHA",
        link: "https://www.youtube.com/watch?v=Y_eRjuktQvI&list=PLiY6ftw6oRV5NamPwNy1dgafOrn3s6tEy&index=4",
        image: images["web_scraping.png"], // "images/portfolio/web_scraping.png"
      },
    },
    desktop: {
      1: {
        title: "Pancel",
        body: "Pancel is a Python-based desktop application to be used for data preprocessing in Machine Learning. It is named Pancel because it mainly uses the Pandas Library. But not just Pandas, and also Plotly for data visualization.",
        link: "https://www.youtube.com/watch?v=mAcFBNUIxms&list=PLiY6ftw6oRV6khDS7T_N2h_cHx-sJ2feg",
        image: images["pancel.png"], // "images/portfolio/pancel.png"
      },
      2: {
        title: "GoogleMaps Search App",
        body: "GoogleMapsSearch is an desktop application that extracts company information from Google Maps.",
        link: "https://www.youtube.com/watch?v=BrVByQ4uCxk&list=PLiY6ftw6oRV5NamPwNy1dgafOrn3s6tEy",
        image: images["web_scraping_and_app.png"], // "images/portfolio/web_scraping_and_app.png"
      },
    },
    product: {
      1: {
        title: "Smartbox Drawer System",
        body: "SMART BOX Slide components move simultaneously, which results in improved drawer functionality where smooth and silent openin-closing movement is guaranteed. Due to developed by latest technology synchronization system, the drawer exceeds all the quality requirements set by furniture designers.",
        link: "https://www.samet.com.tr/en-EN/detail/25/smart-box",
        image: images["smartbox.png"], // "images/portfolio/smartbox.png"
      },
      2: {
        title: "Smart Slide Drawer System",
        body: "Smart Slide Drawer Systems, produced due to SAMET's experience in developing innovative products and its cutting-edge technologies, provide furniture with extra movement, which is the most significant factor in modern living spaces. The state-of-the-art technology used in Smart Slide Drawer Systems adds elegance to opening and closing drawer features.",
        link: "https://www.samet.com.tr/en-EN/detail/19/smart-slide",
        image: images["smartslide.png"], // "images/portfolio/smartslide.png"
      },
      3: {
        title: "Alphabox Drawer System",
        body: "The ALPHABOX new generation drawer system; with minimal design and perfect functional attributes, provides solutions needed for creative furniture industries. While creating a flawless use with its soft-close, push-open and push-open soft close functions, Alphbox provides great convenience with its ease of assembly and installation.",
        link: "https://www.samet.com.tr/en-EN/detail/76/alphabox",
        image: images["alphabox.png"], // "images/portfolio/alphabox.png"
      },
      4: {
        title: "Lift and Slide System",
        body: "VORNE Lift-Slide System provides a wide viewing angle in places with balconies, gardens, terraces, or patios, allowing light to enter the environment better and creating brighter spaces. Designed to bring interior design to a new and spacious look, the Lift-Slide System saves maximum space in living spaces.",
        link: "https://www.vorne.com.tr/en/products/lift-and-slide-system/170",
        image: images["lift_slide.png"], // "images/portfolio/lift_slide.png"
      },
      5: {
        title: "Convection Oven",
        body: "xxxxx",
        link: "https://www.kayalarmutfak.com.tr/en/convection-ovens-40-trays",
        image: images["convection_oven.png"], // "images/portfolio/convection_oven.png"
      },
    },
    patent: {
      1: {
        title: "Connective Corner Fixture",
        body: "Application No: 2008/07405",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=8&ND=3&adjacent=true&locale=en_EP&FT=D&date=20091221&CC=TR&NR=200807405A2&KC=A2",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2008/07405&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2008/07405&documentsTpye=all",
        image: images["patent_connective_corner_fixture.png"], // "images/portfolio/patent_connective_corner_fixture.png"
      },
      2: {
        title: "Push Open System",
        body: "Application No: 2009/02989",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=6&ND=3&adjacent=true&locale=en_EP&FT=D&date=20100721&CC=TR&NR=200902989A2&KC=A2",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/02989&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/02989&documentsTpye=all",
        image: images["patent_push_open.png"], // "images/portfolio/patent_push_open.png"
      },
      3: {
        title: "Closure Mechanism",
        body: "Application No: 2009/02211",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=7&ND=3&adjacent=true&locale=en_EP&FT=D&date=20100322&CC=TR&NR=200902211A2&KC=A2",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/02211&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/02211&documentsTpye=all",
        image: images["patent_closure_mechanism.png"], // "images/portfolio/patent_closure_mechanism.png"
      },
      4: {
        title: "Slowdown-Closing Mech.",
        body: "Application No: 2009/03735",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=5&ND=3&adjacent=true&locale=en_EP&FT=D&date=20100721&CC=TR&NR=200903735A2&KC=A2",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/03735&documentsTpye=all",
        image: images["patent_slowdown_closing_mech.png"], // "images/portfolio/patent_slowdown_closing_mech.png"
      },
      5: {
        title: "Slowdown-Closing Mech.",
        body: "Application No: 2009/06626",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/06626&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/06626&documentsTpye=all",
        image: images["patent_slowdown_closing_mech.png"], // "images/portfolio/patent_slowdown_closing_mech.png"
      },
      6: {
        title: "Push Open System",
        body: "Application No: 2009/09948",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=3&ND=3&adjacent=true&locale=en_EP&FT=D&date=20110321&CC=TR&NR=200909948A2&KC=A2",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2009/09948&documentsTpye=all",
        image: images["patent_push_open_2.png"], // "images/portfolio/patent_push_open_2.png"
      },
      7: {
        title: "Corner Fitting",
        body: "Application No: 2010/01632",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=2&ND=3&adjacent=true&locale=en_EP&FT=D&date=20110721&CC=TR&NR=201001632A2&KC=A2",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2010/01632&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2010/01632&documentsTpye=all",
        image: images["patent_closure_mechanism.png"], // "images/portfolio/patent_closure_mechanism.png"
      },
      8: {
        title: "Door Locking Mech.",
        body: "Application No: 2011/05672",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=1&ND=3&adjacent=true&locale=en_EP&FT=D&date=20120621&CC=TR&NR=201105672A2&KC=A2",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2011/05672&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2011/05672&documentsTpye=all",
        image: images["patent_door_locking.png"], // "images/portfolio/patent_door_locking.png"
      },
      9: {
        title: "Door Locking Mech.",
        body: "Application No: 2011/08160",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?DB=EPODOC&II=0&ND=3&adjacent=true&locale=en_EP&FT=D&date=20130221&CC=WO&NR=2013025181A2&KC=A2",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2011/08160&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2011/08160&documentsTpye=all",
        image: images["patent_door_locking_2.png"], // "images/portfolio/patent_door_locking_2.png"
        note: ": WIPO - WO2013025181 (A3)",
      },
      10: {
        title: "Corner Joint Adaptor",
        body: "Application No: 2012/04792",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/04792&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/04792&documentsTpye=all",
        image: images["patent_corner_joints.png"], // "images/portfolio/patent_corner_joints.png"
      },
      11: {
        title: "Locking Device",
        body: "Application No: 2012/05679",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/05679&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/05679&documentsTpye=all",
        image: images["patent_locking_device.png"], // "images/portfolio/patent_locking_device.png"
      },
      12: {
        title: "Locking Device",
        body: "Application No: 2012/07643",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/07643&documentsTpye=arastirma",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/07643&documentsTpye=all",
        image: images["patent_locking_device_2.png"], // "images/portfolio/patent_locking_device_2.png"
      },
      13: {
        title: "Door Locking Mech.",
        body: "Application No: 2012/15330",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/15330&documentsTpye=all",
        image: images["patent_door_locking_3.png"], // "images/portfolio/patent_door_locking_3.png"
      },
      14: {
        title: "Double-Walled Side Panel",
        body: "Application No: 2012/15628",
        linkSearchReport:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2012/15628&documentsTpye=arastirma",
        image: images["patent_double_walled_side_panel.png"], // "images/portfolio/patent_double_walled_side_panel.png"
      },
      15: {
        title: "Sync. Telescopic Rail",
        body: "Application No: 2013/08144",
        linkDocument:
          "https://portal.turkpatent.gov.tr/anonim/arastirma/patent/sonuc/dosya?patentAppNo=2013/08144&documentsTpye=all",
        image: images["patent_synchronized_telescopic_rail.png"], // "images/portfolio/patent_synchronized_telescopic_rail.png"
      },
      16: {
        title: "Connective Corner Fixture",
        body: "Application No: 2011/08160",
        linkEspace:
          "https://worldwide.espacenet.com/publicationDetails/biblio?CC=WO&NR=2010036215A3&KC=A3&FT=D&ND=4&date=20100812&DB=EPODOC&locale=en_EP",
        linkSearchReport:
          "https://worldwide.espacenet.com/publicationDetails/originalDocument?CC=WO&NR=2010036215A3&KC=A3&FT=D&ND=4&date=20100812&DB=EPODOC&locale=en_EP",
        linkDocument:
          "https://worldwide.espacenet.com/publicationDetails/biblio?CC=WO&NR=2010036215A3&KC=A3&FT=D&ND=4&date=20100812&DB=EPODOC&locale=en_EP",
        image: images["patent_connective_corner_fixture_2.png"], // "images/portfolio/patent_connective_corner_fixture_2.png"
        note: ": WIPO - WO2010036215 (A3)",
      },
    },
    music: {
      1: {
        title: "Cows are here",
        body: "Music: Onur Karagüler",
        link: "https://www.youtube.com/embed/lQFbMNnX-4E",
      },
      2: {
        title: "This World Will Change",
        body: "Music: Onur Karagüler",
        link: "https://www.youtube.com/embed/qi9uXzOABvk",
      },
      3: {
        title: "Dogs",
        body: "Music: Onur Karagüler",
        link: "https://www.youtube.com/embed/BDDICCyIJTM",
      },
      4: {
        title: "They are coming",
        body: "Music: Onur Karagüler",
        link: "https://www.youtube.com/embed/xv32YfS_L3A",
      },
      5: {
        title: "Farewell",
        body: "Music: Onur Karagüler",
        link: "https://www.youtube.com/embed/3zq6aHkG0j8",
      },
      6: {
        title: "Living is Resistance",
        body: "Cover: Onur Karagüler",
        link: "https://www.youtube.com/embed/BUuHODgt_vk",
      },
      7: {
        title: "Getirin Gelini",
        body: "Cover: Onur Karagüler",
        link: "https://www.youtube.com/embed/e6C471LwebY",
      },
    },
  };

  //-----------------------\\
  //*--- Manage Arrows ---*\\
  //-----------------------\\
  // show/hide arrows in the card slider
  const [prevBtnState, setPrevBtnState] = useState(false);
  const [nextBtnState, setNextBtnState] = useState(false);
  const [switchSlideTrigger, setSwitchSlideTrigger] = useState(false);

  // activeSlideNo comes from CategorySlider.js
  const [activeSlideNo, setActiveSlideNo] = useState(false);
  // new activeSlideNo sends to CategorySlider.js
  const [activeSlideNoFromBullets, setActiveSlideNoFromBullets] =
    useState(false);

  // const clickOnArrow = (arg) => {
  //   if (arg === "next") {
  //     setActiveSlideNoFromBullets(activeSlideNo + 1);
  //   } else if (arg === "prev") {
  //     setActiveSlideNoFromBullets(activeSlideNo - 1);
  //   }
  // };

  //----------------------\\
  //*- Filter Portfolio -*\\
  //----------------------\\
  const [portfolioFilter, setPortfolioFilter] = useState("web");
  const [slides, setSlides] = useState(false);
  const [selectedOption, setSelectedOption] = useState("web");
  const portfolioFilterFunc = (e) => {
    setPortfolioFilter(e.target.id);
    setSelectedOption(e.target.id);
  };

  useEffect(() => {
    if (portfolioFilter === "web") {
      setSlides(portfolioItems.web);
    } else if (portfolioFilter === "data") {
      setSlides(portfolioItems.data);
    } else if (portfolioFilter === "scrap") {
      setSlides(portfolioItems.scrap);
    } else if (portfolioFilter === "desktop") {
      setSlides(portfolioItems.desktop);
    } else if (portfolioFilter === "product") {
      setSlides(portfolioItems.product);
    } else if (portfolioFilter === "patent") {
      setSlides(portfolioItems.patent);
    } else if (portfolioFilter === "music") {
      setSlides(portfolioItems.music);
    }
    // setActiveSlideNoFromBullets(0);
  }, [portfolioFilter]);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [cardWidth, setCardWidth] = useState(600);
  const [cardHeight, setCardHeight] = useState(350);
  const [widthType, setWidthType] = useState("px");
  const [gridColumns, setGridColumns] = useState(3);

  const [nextPrevLRDist, setNextPrevLRDist] = useState("1rem");
  useState("0.1vw");

  const [filterItemsHeight, setFilterItemsHeight] = useState(false);
  const [filterItemsFS, setFilterItemsFS] = useState(false);
  const [nextPrevBtnSize, setNextPrevBtnSize] = useState(false);
  const [boxWidth, setBoxWidth] = useState(false);

  const [cardContentH, setCardContentH] = useState(false);
  const [cardContentBottom, setCardContentBottom] = useState(false);
  const [cardTitleAreaH, setCardTitleAreaH] = useState(false);
  const [cardTitleFS, setCardTitleFS] = useState(false);
  const [cardBodyFS, setCardBodyFS] = useState(false);
  const [buttonFS, setButtonFS] = useState(false);

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setCardWidth(96);
      setBoxWidth("96vw"); // same with setCardWidth
      setWidthType("vw");
      setCardHeight(140);
      setGridColumns(1);

      setNextPrevLRDist("1vw");

      setFilterItemsHeight("12.0vw");
      setFilterItemsFS("5.0vw");
      setNextPrevBtnSize("6.0vw");
      setCardContentH("82.0vw");
      setCardContentBottom("-70.0vw");
      setCardTitleAreaH("11.0vw");
      setCardTitleFS("7.0vw");
      setCardBodyFS("5.0vw");
      setButtonFS("5.0vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setCardWidth(30.7);
      setBoxWidth("30.2vw"); // same with setCardWidth
      setWidthType("vw");
      setCardHeight(39);
      setGridColumns(3);

      setNextPrevLRDist(".5vw");

      setFilterItemsHeight("5.0vw");
      setFilterItemsFS("1.8vw");
      setNextPrevBtnSize("2.5vw");
      setCardContentH("25.0vw");
      setCardContentBottom("-21.0vw");
      setCardTitleAreaH("4.0vw");
      setCardTitleFS("2.0vw");
      setCardBodyFS("1.6vw");
      setButtonFS("1.6vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setCardWidth(30.7);
      setBoxWidth("30.2vw"); // same with setCardWidth
      setWidthType("vw");
      setCardHeight(42);
      setGridColumns(3);

      setNextPrevLRDist(".5vw");

      setFilterItemsHeight("7.0vw");
      setFilterItemsFS("2.0vw");
      setNextPrevBtnSize("2.5vw");
      setCardContentH("25.0vw");
      setCardContentBottom("-21.0vw");
      setCardTitleAreaH("4.0vw");
      setCardTitleFS("2.0vw");
      setCardBodyFS("1.6vw");
      setButtonFS("1.6vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setCardWidth(23);
      setBoxWidth("22.5vw"); // same with setCardWidth
      setWidthType("vw");
      setCardHeight(36);
      setGridColumns(4);

      setNextPrevLRDist(".5vw");

      setFilterItemsHeight("6.0vw");
      setFilterItemsFS("1.8vw");
      setNextPrevBtnSize("2.0vw");
      setCardContentH("25.0vw");
      setCardContentBottom("-22.0vw");
      setCardTitleAreaH("3.0vw");
      setCardTitleFS("1.7vw");
      setCardBodyFS("1.40vw");
      setButtonFS("1.3vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setCardWidth(23);
      setBoxWidth("22.5vw"); // same with setCardWidth
      setWidthType("vw");
      setCardHeight(36);
      setGridColumns(4);

      setNextPrevLRDist("1vw");

      setFilterItemsHeight("6.0vw");
      setFilterItemsFS("1.8vw");
      setNextPrevBtnSize("1.8vw");
      setCardContentH("25.0vw");
      setCardContentBottom("-22.0vw");
      setCardTitleAreaH("3.0vw");
      setCardTitleFS("1.7vw");
      setCardBodyFS("1.40vw");
      setButtonFS("1.1vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setCardWidth(350);
      setBoxWidth("345px"); // same with setCardWidth
      setCardHeight(500);
      setWidthType("px");
      setGridColumns(4);

      setNextPrevLRDist("1rem");

      setFilterItemsHeight("5.0rem");
      setFilterItemsFS("1.6rem");
      setNextPrevBtnSize("2.0rem");

      setCardContentH("21.0rem");
      setCardContentBottom("-18.0rem");
      setCardTitleAreaH("3.0rem");
      setCardTitleFS("1.5rem");
      setCardBodyFS("1.29rem");
      setButtonFS("1.1rem");
    }
  }, [windowWidth]);

  //----------------\\
  //*--- Slides ---*\\
  //----------------\\
  const portfolioDivs = Object.values(slides).map((slide, index) => (
    <>
      {portfolioFilter === "music" ? (
        <div className="box music">
          <div className="iframe-container">
            <iframe
              width="300"
              height="315"
              src={slide.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="music_card-content">
            <div className="cardTitleArea">
              <h2 className="cardTitle">{slide.title}</h2>
            </div>
            <p className="cardBody">{slide.body}</p>
          </div>
        </div>
      ) : (
        <div className="box" key={index.toString()}>
          <Image
            className={"image"}
            // width={"100%"}
            height={"100%"}
            image={slide.image}
            info={slide.body}
          />
          {portfolioFilter === "patent" ? (
            <>
              <div className="cardContent">
                <div className="cardTitleArea">
                  <h2 className="cardTitle">{slide.title}</h2>
                </div>
                <p className="cardBody">{slide.body}</p>
                {slide.linkEspace && (
                  <a className="espace" href={slide.linkEspace} target="_blank">
                    Espacenet link
                  </a>
                )}

                {slide.linkSearchReport && (
                  <a
                    className="button left"
                    href={slide.linkSearchReport}
                    target="_blank"
                  >
                    Search Report
                  </a>
                )}

                {slide.linkDocument && (
                  <a
                    className="button right"
                    href={slide.linkDocument}
                    target="_blank"
                  >
                    Document
                  </a>
                )}
              </div>
            </>
          ) : (
            <div className="cardContent">
              <div className="cardTitleArea">
                <h2 className="cardTitle">{slide.title}</h2>
              </div>
              <p className="cardBody">{slide.body}</p>
              <a href={slide.link} className="button right" target="_blank">
                Learn More
              </a>
            </div>
          )}
        </div>
      )}
    </>
  ));

  return (
    <ContainerArea
      className="boxContainer"
      // id="experiencesSliderArea" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      filterItemsHeight={filterItemsHeight}
      filterItemsFS={filterItemsFS}
      nextPrevBtnSize={nextPrevBtnSize}
      boxWidth={boxWidth}
      cardContentH={cardContentH}
      cardContentBottom={cardContentBottom}
      cardTitleAreaH={cardTitleAreaH}
      cardTitleFS={cardTitleFS}
      cardBodyFS={cardBodyFS}
      buttonFS={buttonFS}
      style={{
        padding: windowWidth < 550 && "0.5rem",
      }}
    >
      <div className="filterItems">
        {windowWidth < 550 ? (
          <div className="sortingOption">
            <select
              className="form-control"
              id="searchprice"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setPortfolioFilter(e.target.value);
              }}
            >
              <option value="web">Web Development</option>
              <option value="data"> Data Science </option>
              <option value="scrap"> Web Scraping </option>
              <option value="desktop"> Desktop App </option>
              <option value="product"> Products </option>
              <option value="patent"> Patents </option>
              <option value="music"> MyMusics </option>
            </select>
            <span className="arrow">
              <AngleDownIcon width={"6vw"} height={"6vw"} color={mainColor2} />
            </span>
          </div>
        ) : (
          <ul>
            <li
              className={
                portfolioFilter === "web" ? "filterItem active" : "filterItem"
              }
              id="web"
              onClick={portfolioFilterFunc}
            >
              Web Development
            </li>
            <li
              className={
                portfolioFilter === "data" ? "filterItem active" : "filterItem"
              }
              id="data"
              onClick={portfolioFilterFunc}
            >
              Data Science
            </li>
            <li
              className={
                portfolioFilter === "scrap" ? "filterItem active" : "filterItem"
              }
              id="scrap"
              onClick={portfolioFilterFunc}
            >
              Web Scraping
            </li>
            <li
              className={
                portfolioFilter === "desktop"
                  ? "filterItem active"
                  : "filterItem"
              }
              id="desktop"
              onClick={portfolioFilterFunc}
            >
              Desktop App
            </li>
            <li
              className={
                portfolioFilter === "product"
                  ? "filterItem active"
                  : "filterItem"
              }
              id="product"
              onClick={portfolioFilterFunc}
            >
              Products
            </li>
            <li
              className={
                portfolioFilter === "patent"
                  ? "filterItem active"
                  : "filterItem"
              }
              id="patent"
              onClick={portfolioFilterFunc}
            >
              Patents
            </li>
            <li
              className={
                portfolioFilter === "music" ? "filterItem active" : "filterItem"
              }
              id="music"
              onClick={portfolioFilterFunc}
            >
              MyMusics
            </li>
          </ul>
        )}

        <div className="portfolioSliderBtns">
          <div className="prevBtnArea">
            {prevBtnState && (
              <div
                className="prev"
                onClick={() => setSwitchSlideTrigger("prev")}
              >
                <svg className="prev-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                  ></path>
                </svg>
              </div>
            )}
          </div>
          <div className="nextBtnArea">
            {nextBtnState && (
              <div
                className="next"
                onClick={() => setSwitchSlideTrigger("next")}
              >
                <svg className="next-button-icon" viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                    transform="translate(100, 100) rotate(180) "
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="projectContainer">
        <CategorySlider
          imagesList={portfolioDivs}
          cardWidth={cardWidth}
          scrollableContentWidth={cardWidth * gridColumns}
          scrollableContentHeight={cardHeight}
          widthType={widthType}
          // activeSlideNoFromBullets={activeSlideNoFromBullets}
          autoSlide={false}
          showNextPrevArrow={false}
          nextPrevLRDist={nextPrevLRDist}
          getPrevBtnState={setPrevBtnState}
          getNextBtnState={setNextBtnState}
          // getActiveSlideNo={setActiveSlideNo}
          switchSlideTrigger={switchSlideTrigger}
          setSwitchSlideTrigger={setSwitchSlideTrigger}
        />
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  .filterItems {
    width: 100%;
    height: ${(props) => props.filterItemsHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.5vw;

      li {
        display: inline-block;
        font-size: ${(props) => props.filterItemsFS};
        font-weight: 600;
        color: ${(props) => props.mainColor2};
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.active {
          color: ${(props) => props.mainColor3};
        }

        &:hover {
          color: ${(props) => props.mainColor3};
        }
      }
    }

    .portfolioSliderBtns {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5vw;

      .prevBtnArea {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .nextBtnArea {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .next,
      .prev {
        height: 100%;
        width: ${(props) => props.nextPrevBtnSize};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s linear;
        /* width: ${(props) => props.sliderNextPrevBtnSize};
        height: ${(props) => props.sliderNextPrevBtnSize}; */
        svg {
          width: 100%;
          height: 100%;

          fill: ${(props) => props.mainColor2};
          user-select: none;

          &:hover {
            fill: ${(props) => props.mainColor3};
          }
        }
      }
    }

    .sortingOption {
      width: 55vw;
      height: 80%;
      position: relative;

      select {
        color: ${(props) => props.mainColor2};
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        width: 100%;
        height: 100%;
        padding: 0 1vw;
        border: 0.1vw solid ${(props) => props.mainColor2};
        border-radius: 1.5vw;
        font-size: ${(props) => props.filterItemsFS};
        position: absolute;
        left: 0;
        top: 0;
      }

      .arrow {
        width: 6vw;
        position: absolute;
        right: 1vw;
        top: 2.3vw;
      }
    }
  }

  .projectContainer {
    width: 100%;

    overflow-x: auto;
    /* scroll-behavior: smooth; */

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }

    .box {
      width: ${(props) => props.boxWidth};
      height: 100%;
      border-radius: 0.5rem;
      /* padding: 0 1.2rem; */
      position: relative;
      overflow: hidden;

      .image {
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
      }

      &:hover img {
        transform: scale(1.05);
      }

      &.active {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .cardContent {
        background-color: ${(props) => props.mainColor3};
        --padding: 0.5rem;
        width: 100%;
        height: ${(props) => props.cardContentH};
        position: absolute;
        bottom: ${(props) => props.cardContentBottom}; // move down
        padding: 0 var(--padding);
        transition: all 300ms ease;

        .cardTitleArea {
          width: 100%;
          height: ${(props) => props.cardTitleAreaH}; // move down

          .cardTitle {
            position: relative;
            width: max-content;
            font-size: ${(props) => props.cardTitleFS};
            margin-bottom: ${(props) => props.cardTitleFS};
            color: ${(props) => props.white};

            &::after {
              content: "";
              position: absolute;
              height: 0.2vw;
              left: calc(var(--padding) * -1);
              bottom: -0.5vw;
              width: calc(100% + var(--padding));
              background: #42f584;
              transform: scaleX(0);
              transform-origin: left;
              transition: transform 500ms ease;
            }
          }
        }

        .cardBody {
          font-size: ${(props) => props.cardBodyFS};
          color: ${(props) => props.white};
          word-wrap: break-word;
        }

        .button {
          cursor: pointer;
          display: inline-block;
          text-decoration: none;
          color: ${(props) => props.mainColor};
          background-color: #42f584;

          font-size: ${(props) => props.buttonFS};
          padding: 0.5vw 1.25vw;
          border-radius: 0.25rem;

          position: absolute;
          bottom: 0.7rem;

          &.right {
            right: 0.7rem;
          }
          &.left {
            left: 0.7rem;
          }
        }
      }

      &:hover {
        & .cardContent .cardTitle::after {
          transform: scaleX(1);
        }

        & .cardContent {
          bottom: 0;
          transition-delay: 500ms;
        }
      }

      &.music {
        background-color: ${(props) => props.mainColor3};
        .iframe-container {
          top: 0;
          overflow: auto; // for IOS environment
          width: 100%;
          height: 80%;
          border: none;
          position: absolute;

          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .music_card-content {
          position: absolute;
          --padding: 0.5rem;
          padding: var(--padding);
          width: 100%;
          height: 20%;
          bottom: 0; // move down

          .cardTitle {
            position: relative;
            width: max-content;
            font-size: ${(props) => props.cardTitleFS};
            margin-bottom: ${(props) => props.cardTitleFS};
            color: ${(props) => props.mainColor2};

            &::after {
              content: "";
              position: absolute;
              height: 0.2vw;
              left: calc(var(--padding) * -1);
              bottom: -0.5vw; // move up
              width: calc(100% + var(--padding));
              background: #42f584;
              transform: scaleX(0);
              transform-origin: left;
              transition: transform 500ms ease;
            }
          }

          .cardBody {
            font-size: ${(props) => props.cardBodyFS};
            color: ${(props) => props.mainColor2};
            word-wrap: break-word;
          }
        }

        &:hover {
          .music_card-content .cardTitle::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
`;

export default usePortfolio;
