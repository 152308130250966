import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { FaUserAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import { BiMessageDetail } from "react-icons/bi";
import Button from "../standard/Button";

const SendMessageForm = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //----------------------\\
  //* ------ FORM ------ *\\
  //----------------------\\
  const {
    register: contactFormRegister,
    // reset: contactFormReset,
    handleSubmit: contactFormHandleSubmit,
    formState: { errors },
    // watch: contactWatch,
  } = useForm();

  const contactFormOnSubmit = (data) => {
    let firstName = data.firstName;
    let lastName = data.lastName;
    let email = data.email;
    let phone = data.phone;
    let contactMessage = data.contactMessage;

    window.Email.send({
      To: "onurkaraguler@hotmail.com",
      From: "onurportfoliowebpage@gmail.com ",
      SecureToken: "c029fae7-b932-467f-bacd-1a3958c3c95d",
      Subject: "Email from my portfolio webpage",
      Body:
        "<br><br><br>İsim Soyisim: " +
        firstName +
        " " +
        lastName +
        "<br>Email: " +
        email +
        "<br>Telefon: " +
        phone +
        "<br>Mesaj: " +
        contactMessage,
    }).then((message) => alert(message));
  };

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [contactFormWidth, setContactFormWidth] = useState(false);
  const [formElementWidth, setFormElementWidth] = useState("41vw");
  const [formElementsGap, setFormElementsGap] = useState(false);
  const [firstLastnameGap, setFirstLastnameGap] = useState(false);
  const [inputAreaHeight, setInputAreaHeight] = useState("3vw");
  const [iconAreaWidth, setIconAreaWidth] = useState("8vw");
  const [inputAreaPadding, setInputAreaPadding] = useState(false);
  const [inputFS, setInputFS] = useState("1.1vw");
  const [textareaHeight, setTextareaHeight] = useState("8vw");
  const [formBtnWidth, setFormBtnWidth] = useState("10vw");
  const [formBtnHeight, setFormBtnHeight] = useState("3vw");
  const [formBtnFS, setFormBtnFS] = useState("1.4vw");
  const [errorAreaFS, setErrorAreaFS] = useState(false);

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setContactFormWidth("100%");
      setFormElementWidth("100%");
      setFormElementsGap("2vw");
      setFirstLastnameGap("1.5vw");
      setInputAreaHeight("8vw");
      setIconAreaWidth("8vw");
      setInputAreaPadding("0.4rem");
      setInputFS("5vw");
      setTextareaHeight("45vw");
      setFormBtnWidth("100%");
      setFormBtnHeight("8vw");
      setFormBtnFS("4vw");
      setErrorAreaFS("4v4");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setContactFormWidth("45vw");
      setFormElementWidth("45vw");
      setFormElementsGap("1vw");
      setFirstLastnameGap("1.0vw");
      setInputAreaHeight("3.5vw");
      setIconAreaWidth("3.5vw");
      setInputAreaPadding("0.4rem");
      setInputFS("1.8vw");
      setTextareaHeight("14vw");
      setFormBtnWidth("16vw");
      setFormBtnHeight("3.5vw");
      setFormBtnFS("1.6vw");
      setErrorAreaFS("1.4vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setContactFormWidth("45vw");
      setFormElementWidth("45vw");
      setFormElementsGap("1vw");
      setFirstLastnameGap("1.0vw");
      setInputAreaHeight("4vw");
      setIconAreaWidth("4vw");
      setInputAreaPadding("0.5rem");
      setInputFS("1.8vw");
      setTextareaHeight("14vw");
      setFormBtnWidth("16vw");
      setFormBtnHeight("4vw");
      setFormBtnFS("1.8vw");
      setErrorAreaFS("1.8vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setContactFormWidth("45vw");
      setFormElementWidth("45vw");
      setFormElementsGap("1vw");
      setFirstLastnameGap("1.0vw");
      setInputAreaHeight("3.5vw");
      setIconAreaWidth("3.5vw");
      setInputAreaPadding("0.6rem");
      setInputFS("1.6vw");
      setTextareaHeight("14vw");
      setFormBtnWidth("16vw");
      setFormBtnHeight("3.5vw");
      setFormBtnFS("1.6vw");
      setErrorAreaFS("1.4vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setContactFormWidth("45vw");
      setFormElementWidth("45vw");
      setFormElementsGap("1vw");
      setFirstLastnameGap("1vw");
      setInputAreaHeight("3.5vw");
      setIconAreaWidth("3.5vw");
      setInputAreaPadding("0.7rem");
      setInputFS("1.6vw");
      setTextareaHeight("14vw");
      setFormBtnWidth("14vw");
      setFormBtnHeight("3.5vw");
      setFormBtnFS("1.6vw");
      setErrorAreaFS("1.4vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setContactFormWidth("43rem");
      setFormElementWidth("43rem");
      setFormElementsGap("1rem");
      setFirstLastnameGap("1rem");
      setInputAreaHeight("3.5rem");
      setIconAreaWidth("3.5rem");
      setInputAreaPadding("0.7rem");
      setInputFS("1.6rem");
      setTextareaHeight("14rem");
      setFormBtnWidth("14rem");
      setFormBtnHeight("3.5rem");
      setFormBtnFS("1.6rem");
      setErrorAreaFS("1.4rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <FormArea
      method="POST"
      id="contactForm"
      onSubmit={contactFormHandleSubmit(contactFormOnSubmit)}
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      contactFormWidth={contactFormWidth}
      formElementWidth={formElementWidth}
      formElementsGap={formElementsGap}
      inputAreaHeight={inputAreaHeight}
      iconAreaWidth={iconAreaWidth}
      inputAreaPadding={inputAreaPadding}
      inputFS={inputFS}
      textareaHeight={textareaHeight}
      firstLastnameGap={firstLastnameGap}
      errorAreaFS={errorAreaFS}
    >
      <div className="titleElement">
        <h3 className="title" id="register_title_alert">
          Send a Message
        </h3>
      </div>

      <div className="formElements">
        <div className="firstLastname">
          <div className="formElement">
            <div className="inputArea">
              <div className="iconArea">
                <FaUserAlt className="icon" />
              </div>
              <input
                type="text"
                className="firstName"
                autoComplete="name"
                name="firstName"
                placeholder="First Name"
                {...contactFormRegister("firstName", {
                  required: "İsim bilgileri gerekli!",
                  pattern: {
                    value: /^[a-zA-Zçğıöşü\s]+$/i,
                    message: "İsim' de sadece harfler olmalı!",
                  },
                  maxLength: {
                    value: 20,
                    message: "İsim' de en fazla 20 harf olmalı",
                  },
                  // validate: async (value) => {
                  //   await (value === "Onur");
                  //   return true;
                  // },
                })}
              />
            </div>
            <p className="errorArea">{errors.firstName?.message}</p>
          </div>

          <div className="formElement">
            <div className="inputArea">
              <div className="iconArea">
                <FaUserAlt className="icon" />
              </div>
              <input
                type="text"
                className="lastName"
                autoComplete="name"
                name="lastName"
                placeholder="Last Name"
                {...contactFormRegister("lastName", {
                  required: "Soyisim bilgileri gerekli!",
                  pattern: {
                    value: /^[a-zA-Zçğıöşü\s]+$/i,
                    message: "Soyisim' de sadece harfler olmalı!",
                  },
                  maxLength: {
                    value: 40,
                    message: "Soyisim' de en fazla 20 harf olmalı",
                  },
                  // validate: async (value) => {
                  //   await (value === "Onur");
                  //   return true;
                  // },
                })}
              />
            </div>
            <p className="errorArea">{errors.lastName?.message}</p>
          </div>
        </div>

        <div className="formElement">
          <div className="inputArea">
            <div className="iconArea">
              <FaEnvelope className="icon" />
            </div>
            <input
              type="email"
              className="email"
              placeholder="Email"
              autoComplete="email"
              name="email"
              required="	"
              {...contactFormRegister("email", {
                required: "E-posta adresi gerekli!",
              })}
            />
          </div>
          <p className="errorArea">{errors.email?.message}</p>
        </div>

        <div className="formElement">
          <div className="inputArea">
            <div className="iconArea">
              <FaPhone className="icon" />
            </div>
            <input
              type="tel"
              className="phone"
              autoComplete="phone"
              name="phone"
              // id="phone"
              placeholder="Phone"
              // value="+90"
              required="	"
              {...contactFormRegister("phone", {
                required: "Telefon numarasi gerekli!",
              })}
            />
          </div>
          <p className="errorArea">{errors.phone?.message}</p>
        </div>

        <div className="formElement textarea">
          <div className="inputArea">
            <div className="iconArea">
              <BiMessageDetail className="icon" />
            </div>

            <textarea
              className="contactMessage"
              id="contactMessage"
              name="message"
              // rows="4"
              placeholder="Write your message here..."
              {...contactFormRegister("contactMessage", {
                required: "Mesaj bilgileri gerekli!",
                // pattern: {
                //   value: /^[a-zA-Zçğıöşü\s]+$/i,
                //   message: "Soyisim' de sadece harfler olmalı!",
                // },
                // maxLength: {
                //   value: 40,
                //   message: "Soyisim' de en fazla 20 harf olmalı",
                // },
                // validate: async (value) => {
                //   await (value === "Onur");
                //   return true;
                // },
              })}
            ></textarea>
          </div>
          {/* <label htmlFor="">Mesaj</label> */}
          <span id="messaje-error"></span>
        </div>
      </div>

      <Button
        type={"submit"}
        className={"formBtn"}
        size={[formBtnWidth, formBtnHeight]} // [width, height]
        backgroundColor={`${mainColor3} !important`}
        color={mainColor2}
        fontSize={formBtnFS}
        fontWeight={"500"}
      >
        Send
      </Button>
    </FormArea>
  );
};

const FormArea = styled.form`
  width: ${(props) => props.contactFormWidth};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .formElements {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.formElementsGap};

    .formElement {
      width: ${(props) => props.formElementWidth};
      display: flex;
      /* for warning */
      flex-direction: column;
      position: relative;

      .inputArea {
        width: 100%;
        height: ${(props) => props.inputAreaHeight};

        display: flex;

        .iconArea {
          background-color: ${(props) => props.mainColor2};
          height: 100%;
          width: ${(props) => props.iconAreaWidth};
          min-width: ${(props) => props.iconAreaWidth};
          padding: ${(props) => props.inputAreaPadding};
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            height: 100%;
            width: 100%;
          }
        }

        input {
          width: 100%;
          height: 100%;
          outline: none;
          border: 0.1rem, solid ${(props) => props.mainColor2};
          // background-color: transparent;
          padding-left: 1rem;
          font-size: ${(props) => props.inputFS};

          // Change the color of a input-field when autofilled
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 1000s ease-in-out 0s;
          }

          &:focus {
            border: 0.1rem solid ${(props) => props.mainColor3};
          }

          &::placeholder {
            color: ${(props) => props.gray};
            font-size: calc(${(props) => props.inputFS} * 0.8);
          }
        }
      }

      &.textarea {
        height: ${(props) => props.textareaHeight};
        .iconArea {
          height: ${(props) => props.textareaHeight};
        }
        textarea {
          width: 100%;
          height: ${(props) => props.textareaHeight};
          font-size: ${(props) => props.inputFS};
          padding: 0.5rem 1rem;
          resize: none;
          overflow-y: auto;

          &::placeholder {
            color: ${(props) => props.gray};
            font-size: calc(${(props) => props.inputFS} * 0.8);
          }

          &:focus {
            border: 0.1rem solid ${(props) => props.mainColor3};
          }
        }
      }

      .errorArea {
        color: ${(props) => props.mainColor3};
        margin-top: 0.1rem;
        font-size: ${(props) => props.errorAreaFS};
      }

      .pw_info {
        color: ${(props) => props.gray};
        margin-top: 0.5rem;
      }
    }

    .firstLastname {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${(props) => props.firstLastnameGap};
      .formElement {
        width: 50%;
      }
    }
  }

  .formBtn {
    margin-top: ${(props) => props.formElementsGap};
    border-radius: 0.3rem;
  }
`;

export default SendMessageForm;
