import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import mainLogo from "../../images/mainLogo.png";
import { CloseIcon } from "../standard/Icons";
import {
  LinkedinIcon,
  GithubIcon,
  KaggleIcon,
  YoutubeIcon,
} from "../standard/Icons";

import { setSideLinksContState } from "../stores/generalSlice";

const SideLinksContainer = () => {
  const dispatch = useDispatch();

  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  const sideLinksContState = useSelector(
    (state) => state.generalStore.sideLinksContState
  );

  const sideLinksContHide = () => {
    dispatch(setSideLinksContState(false));
  };

  //-----------------------\\
  //*-- Prevent Scrolling --\\
  //-----------------------\\
  useEffect(() => {
    if (sideLinksContState) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sideLinksContState]);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [containerWidth, setContainerWidth] = useState("60vw");
  const [headerHeight, setHeaderHeight] = useState("8vw");
  const [spotlightHeight, setSpotlightHeight] = useState("1vw");
  const [spotlightFS, setSpotlightFS] = useState("1vw");
  const [closeBtnFS, setCloseBtnFS] = useState("3vw");
  const [bodyItemGap, setBodyItemGap] = useState("2vw");
  const [menuPaddingLeft, setMenuPaddingLeft] = useState("2vw");
  const [itemFS, setItemFS] = useState("3.0vw");
  const [logoHeight, setLogoHeight] = useState("70%");

  const [socialLinksHeight, setSocialLinksHeight] = useState("12vw");
  const [socialLinksSize, setSocialLinksSize] = useState("4vw");
  const [socialIconsSize, setSocialIconsSize] = useState("2vw");
  const [socialLinksGap, setSocialLinksGap] = useState("1vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setContainerWidth("55vw");

      setHeaderHeight("12vw");
      setSpotlightHeight("5.5vw");
      setSpotlightFS("4.5vw");

      setCloseBtnFS("5vw");
      setBodyItemGap("3vw");
      setMenuPaddingLeft("5vw");
      setItemFS("5.6vw");
      setLogoHeight("70%");

      setSocialLinksHeight("12vw");
      setSocialLinksSize("8vw");
      setSocialIconsSize("4.5vw");
      setSocialLinksGap("3vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setContainerWidth("35vw");

      setHeaderHeight("8vw");
      setSpotlightHeight("3.5vw");
      setSpotlightFS("2.5vw");

      setCloseBtnFS("3vw");
      setBodyItemGap("1.5vw");
      setMenuPaddingLeft("4vw");
      setItemFS("3.4vw");
      setLogoHeight("70%");

      setSocialLinksHeight("8vw");
      setSocialLinksSize("4.5vw");
      setSocialIconsSize("2.7vw");
      setSocialLinksGap("2vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setContainerWidth("35vw");

      setHeaderHeight("7vw");
      setSpotlightHeight("3.0vw");
      setSpotlightFS("2.0vw");

      setCloseBtnFS("3vw");
      setBodyItemGap("1.5vw");
      setMenuPaddingLeft("3vw");
      setItemFS("3.2vw");
      setLogoHeight("80%");

      setSocialLinksHeight("6vw");
      setSocialLinksSize("4.2vw");
      setSocialIconsSize("2.3vw");
      setSocialLinksGap("1.8vw");
    }
  }, [windowWidth]);

  return (
    <>
      <SideLinksContainerArea
        className="basketSideMenu"
        id="basketSideMenu"
        mainColor={mainColor}
        mainColor2={mainColor2}
        mainColor3={mainColor3}
        mainColor4={mainColor4}
        lightgray={lightgray}
        gray={gray}
        darkgray={darkgray}
        white={white}
        containerWidth={containerWidth}
        headerHeight={headerHeight}
        spotlightHeight={spotlightHeight}
        spotlightFS={spotlightFS}
        closeBtnFS={closeBtnFS}
        bodyItemGap={bodyItemGap}
        menuPaddingLeft={menuPaddingLeft}
        itemFS={itemFS}
        logoHeight={logoHeight}
        socialLinksHeight={socialLinksHeight}
        socialLinksSize={socialLinksSize}
        socialIconsSize={socialIconsSize}
        socialLinksGap={socialLinksGap}
      >
        {sideLinksContState && (
          <div
            className="mainPageCover"
            style={{
              zIndex: -1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              width: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              padding: 0,
              margin: 0,
            }}
            onClick={sideLinksContHide}
          ></div>
        )}

        <div
          className={
            sideLinksContState ? "linksContent active" : "linksContent"
          }
        >
          <div className="sideHeader">
            <Link className="logo" to="/">
              <img src={mainLogo} alt="Onur Karaüler Logo" />
            </Link>
            <div className="closeBtn" onClick={sideLinksContHide}>
              <CloseIcon
                width={closeBtnFS}
                height={closeBtnFS}
                color={mainColor2}
              />
            </div>
          </div>

          <HashLink
            smooth
            to="/#header"
            className="spotlight"
            onClick={sideLinksContHide}
          >
            React Developer
          </HashLink>

          <div className="body">
            <div className="menu">
              <ul className="items">
                <li className="item" onClick={sideLinksContHide}>
                  <Link to="/" onClick={(e) => window.scrollTo(0, 0)}>
                    <span>Home</span>
                  </Link>
                </li>
                <li className="item" onClick={sideLinksContHide}>
                  <HashLink smooth to="/#aboutme">
                    <span>About Me</span>
                  </HashLink>
                </li>
                <li className="item" onClick={sideLinksContHide}>
                  <HashLink smooth to="/#edu">
                    <span>Education</span>
                  </HashLink>
                </li>
                <li className="item" onClick={sideLinksContHide}>
                  <HashLink smooth to="/#skills">
                    <span>Skills</span>
                  </HashLink>
                </li>
                <li className="item" onClick={sideLinksContHide}>
                  <HashLink smooth to="/#experiences">
                    <span>Experiences</span>
                  </HashLink>
                </li>
                <li className="item" onClick={sideLinksContHide}>
                  <HashLink smooth to="/#portfolio">
                    <span>Portfolio</span>
                  </HashLink>
                </li>
                <li className="item" onClick={sideLinksContHide}>
                  <HashLink smooth to="/#contact">
                    <span>Contact</span>
                  </HashLink>
                </li>
              </ul>
            </div>

            <div className="socialLinks">
              <div className="icons">
                <a
                  href="https://www.linkedin.com/in/onurkaraguler/"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <div className="iconArea">
                    <LinkedinIcon
                      width={socialIconsSize}
                      height={socialIconsSize}
                      color={mainColor2}
                    />
                  </div>
                </a>
                <a
                  href="https://github.com/OnurKaraguler?tab=repositories"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <div className="iconArea">
                    <GithubIcon
                      width={socialIconsSize}
                      height={socialIconsSize}
                      color={mainColor2}
                    />
                  </div>
                </a>
                <a
                  href="https://www.kaggle.com/onurkaraguler"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <div className="iconArea">
                    <KaggleIcon
                      width={socialIconsSize}
                      height={socialIconsSize}
                      color={mainColor2}
                    />
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCFjIQycch3JwHFZi_57idVw"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <div className="iconArea">
                    <YoutubeIcon
                      width={socialIconsSize}
                      height={socialIconsSize}
                      color={mainColor2}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SideLinksContainerArea>
    </>
  );
};

const SideLinksContainerArea = styled.div`
  z-index: 100;

  .linksContent {
    background-color: ${(props) => props.lightgray};
    position: fixed;
    right: 0;
    top: 0;
    width: ${(props) => props.containerWidth};
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow-y: scroll; // Scrollable Menu

    transition: all 0.3s;
    transform: translateX(calc(+${(props) => props.containerWidth} + 2vw));

    display: flex;
    flex-direction: column;
    align-items: center;

    opacity: 0;

    &.active {
      transform: translateX(0);
      opacity: 1;
    }

    .sideHeader {
      background-color: ${(props) => props.mainColor4};
      width: 100%;
      height: ${(props) => props.headerHeight};

      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: ${(props) => props.logoHeight};
        padding-left: ${(props) => props.menuPaddingLeft};
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          user-select: none;
        }
      }

      .closeBtn {
        cursor: pointer;
        margin-right: 2vw;
      }
    }

    .spotlight {
      background-color: ${(props) => props.mainColor3};
      width: 100%;
      height: ${(props) => props.spotlightHeight};
      padding-left: ${(props) => props.menuPaddingLeft};
      color: ${(props) => props.white};
      font-size: ${(props) => props.spotlightFS};

      display: flex;
      align-items: center;
    }

    .body {
      width: 100%;
      gap: ${(props) => props.bodyItemGap};
      padding-top: 1rem;

      // Scrollable Menu
      white-space: nowrap;
      overflow-y: scroll;

      display: flex;
      flex-direction: column;

      .menu {
        width: 100%;
        padding-left: ${(props) => props.menuPaddingLeft};

        .items {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          gap: ${(props) => props.bodyItemGap};

          .item {
            font-size: ${(props) => props.itemFS};
            white-space: normal; // wrap text

            a {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: relative;

              color: ${(props) => props.darkgray};

              .arrow {
                display: inline-block;
                margin-right: 0.3vw;
              }
            }
          }
        }
      }

      .socialLinks {
        background-color: ${(props) => props.mainColor4};
        width: 100%;
        height: ${(props) => props.socialLinksHeight};
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;

        .icons {
          display: flex;
          align-items: center;
          gap: ${(props) => props.socialLinksGap};
          padding-left: ${(props) => props.menuPaddingLeft};

          .iconLink {
            width: ${(props) => props.socialLinksSize};
            height: ${(props) => props.socialLinksSize};
            color: ${(props) => props.mainColor2};
            border: 0.2vw solid ${(props) => props.white};
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: ${(props) => props.socialIconsSize};
              height: ${(props) => props.socialIconsSize};
            }
          }
        }
      }
    }
  }
`;

export default SideLinksContainer;
