import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Image from "../standard/Image";
import iku from "../../images/iku.png";
import iu from "../../images/iu.png";
import trakya from "../../images/trakya.png";

const Education = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  // const [containerHeight, setContainerHeight] = useState("900px");
  const [headerHeight, setHeaderHeight] = useState("13rem");
  const [headerFS, setHeaderFS] = useState("3rem");
  const [gridCols, setGridCols] = useState(3);

  const [imageWidth, setImageWidth] = useState("90%");
  const [imageHeight, setImageHeight] = useState("32rem");
  const [uniHeight, setUniHeight] = useState("3rem");
  const [uniFS, setUniFS] = useState("2rem");
  const [studyHeight, setStudyHeight] = useState("4rem");
  const [studyFS, setStudyFS] = useState("3rem");
  const [dateHeight, setDateHeight] = useState("3rem");
  const [dateFS, setDateFS] = useState("2rem");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      // setContainerHeight("240vw");
      setHeaderHeight("20vw");
      setHeaderFS("8vw");
      setGridCols(1);
      setImageWidth("80vw");
      setImageHeight("25vw");
      setUniHeight("8vw");
      setUniFS("4.0vw");
      setStudyHeight("12.0vw");
      setStudyFS("5.0vw");
      setDateHeight("8.0vw");
      setDateFS("4.0vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      // setContainerHeight("50vw");
      setHeaderHeight("13vw");
      setHeaderFS("4vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("9vw");
      setUniHeight("3vw");
      setUniFS("1.9vw");
      setStudyHeight("6.0vw");
      setStudyFS("2.1vw");
      setDateHeight("3.0vw");
      setDateFS("2.0vw");
    }
    // tablet portrait
    else if (
      windowWidth < 850
      // windowWidth < 850 &&
      // windowWidth >= 550 &&
      // windowHeight < 1200 &&
      // windowHeight >= 550
    ) {
      // setContainerHeight("52vw");
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("9vw");
      setUniHeight("3vw");
      setUniFS("1.9vw");
      setStudyHeight("6.0vw");
      setStudyFS("2.2vw");
      setDateHeight("3.0vw");
      setDateFS("2.0vw");
    }
    // tablet landscape
    else if (
      windowWidth < 1200
      // windowWidth < 1200 &&
      // windowWidth >= 550 &&
      // windowHeight < 850 &&
      // windowHeight >= 550
    ) {
      // setContainerHeight("55vw");
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("10vw");
      setUniHeight("3vw");
      setUniFS("2.0vw");
      setStudyHeight("6.0vw");
      setStudyFS("2.2vw");
      setDateHeight("3.0vw");
      setDateFS("2.0vw");
    }

    // computer
    else if (windowWidth < 1400) {
      // setContainerHeight("54vw");
      setHeaderHeight("15vw");
      setHeaderFS("4.6vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("11vw");
      setUniHeight("3vw");
      setUniFS("2.1vw");
      setStudyHeight("7.0vw");
      setStudyFS("2.5vw");
      setDateHeight("3.0vw");
      setDateFS("2.0vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      // setContainerHeight("750px");
      setHeaderHeight("13rem");
      setHeaderFS("4rem");
      setGridCols(3);
      setImageWidth("250px");
      setImageHeight("150px");
      setUniHeight("3rem");
      setUniFS("1.8rem");
      setStudyHeight("6rem");
      setStudyFS("2.0rem");
      setDateHeight("3.0rem");
      setDateFS("1.8rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContainerArea
      id="edu" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      headerHeight={headerHeight}
      headerFS={headerFS}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      uniHeight={uniHeight}
      uniFS={uniFS}
      studyHeight={studyHeight}
      studyFS={studyFS}
      dateHeight={dateHeight}
      dateFS={dateFS}
      style={
        {
          // height: containerHeight,
        }
      }
    >
      <div
        className="main"
        style={{
          width: containerWidth,
        }}
      >
        <div className="header">
          <h1 className="heading">
            <span>EDUCATION</span>
          </h1>
        </div>

        <div
          className="boxes"
          style={{
            gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
            width: containerWidth,
            padding: windowWidth < 550 && "0 0.5rem",
          }}
        >
          <div className="box">
            <div className="img">
              <a
                href="https://enstitu.iku.edu.tr/en/phd/business-administration-phd-program"
                target="_blank"
              >
                <Image
                  className={"image"}
                  // width={"100%"}
                  height={"100%"}
                  image={iku}
                  info={"onur karagüler istanbul kültür üniversitesi"}
                />
              </a>
            </div>

            <div className="uni">
              <h1>Istanbul Kültür University</h1>
            </div>

            <div className="study">
              <h2>Business Administration (PhD)</h2>
            </div>

            <div className="date">
              <h3>2013 - ……</h3>
            </div>
          </div>
          <div className="box">
            <div className="img">
              <a
                href="https://isletmeiktisadi.istanbul.edu.tr/en/content/student/executive-mba"
                target="_blank"
              >
                <Image
                  className={"image"}
                  // width={"100%"}
                  height={"100%"}
                  image={iu}
                  info={"onur karagüler istanbul üniversitesi"}
                />
              </a>
            </div>

            <div className="uni">
              <h1>Istanbul University (English)</h1>
            </div>

            <div className="study">
              <h2>Master of Business Administration (MBA)</h2>
            </div>

            <div className="date">
              <h3>2011 - 2012</h3>
            </div>
          </div>
          <div className="box">
            <div className="img">
              <a href="https://muhendislik-en.trakya.edu.tr" target="_blank">
                <Image
                  className={"image"}
                  // width={"100%"}
                  height={"100%"}
                  image={trakya}
                  info={"onur karagüler trakya üniversitesi"}
                />
              </a>
            </div>
            <div className="uni">
              <h1>Trakya University</h1>
            </div>

            <div className="study">
              <h2>Mechanical Engineer</h2>
            </div>

            <div className="date">
              <h3>1995 - 2000</h3>
            </div>
          </div>
        </div>
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    .header {
      display: block;
      width: 100%;
      height: ${(props) => props.headerHeight};

      display: flex;
      justify-content: center;
      align-items: center;

      .heading {
        text-align: center;
        color: ${(props) => props.darkgray};
        font-size: ${(props) => props.headerFS};
      }
    }

    .boxes {
      width: 100%;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 1rem;
      /* grid-column-gap: 1vw;
      grid-row-gap: 1vw; */

      .box {
        padding: 1rem;
        border: 0.2rem solid ${(props) => props.darkgray};
        border-radius: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image {
          width: ${(props) => props.imageWidth};
          height: ${(props) => props.imageHeight};
        }

        .uni {
          width: 100%;
          height: ${(props) => props.uniHeight};

          display: flex;
          align-items: center;
          justify-content: center;

          h1 {
            text-align: center;
            font-size: ${(props) => props.uniFS};
          }
        }

        .study {
          width: 100%;
          height: ${(props) => props.studyHeight};

          display: flex;
          align-items: center;
          justify-content: center;

          h2 {
            font-size: ${(props) => props.studyFS};
            text-align: center;
            color: ${(props) => props.mainColor3};
          }
        }

        .date {
          width: 100%;
          height: ${(props) => props.dateHeight};

          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            font-size: ${(props) => props.dateFS};
            text-align: center;
          }
        }
      }
    }
  }
`;

export default Education;
