import React from "react";
import Image from "./Image";
import Video from "./Video";
import styled from "styled-components";
import { useSelector } from "react-redux";

const MainSliderBullets = ({
  imagesList,
  widthType,
  bulletWidth,
  bulletHeight,
  bulletGap,
  onClick,
  activeSlideNo,
  showHideNextBtn,
  showHidePrevBtn,
  borderRadius,
  autoSlide,
}) => {
  const clickOnBullet = (index) => {
    onClick(index);
  };

  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const white = useSelector((state) => state.generalStore.white);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  return (
    <BulletArea
      mainColor={mainColor}
      mainColor2={mainColor2}
      white={white}
      gray={gray}
      darkgray={darkgray}
      borderRadius={borderRadius}
      className="bulletContainer"
      // style={{
      //   height: `${bulletHeight + 1}vw`,
      // }}
    >
      <div
        className="bullets"
        style={{
          display: "flex",
          gap: widthType === "vw" ? `${bulletGap}vw` : `${bulletGap}px`,
        }}
        onMouseOver={() => {
          showHideNextBtn(false);
          showHidePrevBtn(false);
        }}
        onMouseLeave={() => {
          showHideNextBtn(true);
          showHidePrevBtn(true);
        }}
      >
        {imagesList.map((img, index) => (
          <div
            className={activeSlideNo === index ? "bullet active" : "bullet"}
            key={index.toString()}
            onClick={() => clickOnBullet(index)}
            onMouseOver={() => clickOnBullet(index)}
          >
            {img.image ? (
              <Image
                width={
                  widthType === "vw" ? `${bulletWidth}vw` : `${bulletWidth}px`
                }
                height={
                  widthType === "vw" ? `${bulletHeight}vw` : `${bulletHeight}px`
                }
                // height={`${bulletHeight}vw`}
                image={imagesList[index].image}
                info={imagesList[index].info}
              />
            ) : (
              <Video
                width={
                  widthType === "vw" ? `${bulletWidth}vw` : `${bulletWidth}px`
                }
                height={
                  widthType === "vw" ? `${bulletHeight}vw` : `${bulletHeight}px`
                }
                // width={`${bulletWidth}vw`}
                // height={`${bulletHeight}vw`}
                // video={img.video}
                video={imagesList[index].video}
                autoPlay={false}
                loop={false}
                muted={false}
                controls={false}
                forBullet={true}
                // info={slides[index + 1].header}
              />
            )}

            {autoSlide && <div className="bulletCover"></div>}
          </div>
        ))}
      </div>
    </BulletArea>
  );
};

const BulletArea = styled.div`
  /* background-color: rgba(0, 0, 0, 0.2); */
  display: flex;
  align-items: center;
  justify-content: center;

  .bullets {
    display: flex;
    align-items: center;
    justify-content: center;

    .bullet {
      width: 100%;
      height: 100%;
      border: 0.1rem solid ${(props) => props.mainColor};
      border-radius: ${(props) => props.borderRadius};
      background: ${(props) => props.gray};

      cursor: pointer;
      position: relative;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        transition: all 0.3s ease-in-out;
      }

      &.active {
        border: 0.2rem solid ${(props) => props.mainColor2};

        .bulletCover {
          -webkit-animation: bulletCoverAni 10s ease-in-out forwards;
          animation: bulletCoverAni 10s ease-in-out forwards;
        }
      }

      .bulletCover {
        background-color: ${(props) => props.mainColor2};
        /* background-color: rgba(0, 0, 0, 0.5); */
        width: 0%;
        height: 100%;
        position: absolute;
        left: 0;
        opacity: 0.7;
      }

      @-webkit-keyframes bulletCoverAni {
        0% {
          visibility: hidden;
        }
        100% {
          visibility: visible;
        }
      }
      @keyframes bulletCoverAni {
        from {
          width: 0%;
        }
        to {
          width: 100%;
        }
      }
    }
  }
`;

export default MainSliderBullets;
