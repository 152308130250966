import React from "react";
import styled from "styled-components";
import {
  FaTruck,
  FaAngleRight,
  FaAngleDown,
  FaPhone,
  FaEnvelope,
  FaCrown,
  FaTimes,
  FaReact,
  FaPython,
  FaGithub,
  FaKaggle,
  FaLinkedin,
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { VscWorkspaceTrusted, VscCreditCard } from "react-icons/vsc";
import { GiHandTruck } from "react-icons/gi";
import { SiGooglemaps } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import {
  AiOutlineAreaChart,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineClose,
} from "react-icons/ai";

//---------------\\
//*--- Truck ---*\\
//---------------\\
export const TruckIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaTruck className="chartIcon" />
    </IconArea>
  );
};

//----------------------\\
//*- WorkspaceTrusted -*\\
//----------------------\\
export const WorkspaceTrustedIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <VscWorkspaceTrusted className="chartIcon" />
    </IconArea>
  );
};

//----------------------\\
//*- WorkspaceTrusted -*\\
//----------------------\\
export const CreditCardIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <VscCreditCard className="chartIcon" />
    </IconArea>
  );
};

//-----------------------\\
//*---- GiHandTruck ----*\\
//-----------------------\\
export const GiHandTruckIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <GiHandTruck className="chartIcon" />
    </IconArea>
  );
};

//----------------------\\
//*---- AngleRight ----*\\
//----------------------\\
export const AngleRightIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaAngleRight className="chartIcon" />
    </IconArea>
  );
};

//----------------------\\
//*---- AngleDown ----*\\
//----------------------\\
export const AngleDownIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaAngleDown className="chartIcon" />
    </IconArea>
  );
};

//-----------------\\
//*---- Phone ----*\\
//-----------------\\
export const PhoneIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaPhone className="chartIcon" />
    </IconArea>
  );
};

//--------------------\\
//*---- Envelope ----*\\
//--------------------\\
export const EnvelopeIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaEnvelope className="chartIcon" />
    </IconArea>
  );
};

//-----------------\\
//*---- Crown ----*\\
//-----------------\\
export const CrownIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaCrown className="chartIcon" />
    </IconArea>
  );
};

//-----------------\\
//*---- Crown ----*\\
//-----------------\\
export const TimesIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaTimes className="chartIcon" />
    </IconArea>
  );
};

//-----------------\\
//*--- Website ---*\\
//-----------------\\
export const WebsiteIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <CgWebsite className="chartIcon" />
    </IconArea>
  );
};
export const ReactIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaReact className="chartIcon" />
    </IconArea>
  );
};
export const DataScienceIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <AiOutlineAreaChart className="chartIcon" />
    </IconArea>
  );
};
export const PythonIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaPython className="chartIcon" />
    </IconArea>
  );
};
export const GithubIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaGithub className="chartIcon" />
    </IconArea>
  );
};
export const KaggleIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaKaggle className="chartIcon" />
    </IconArea>
  );
};
export const LinkedinIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaLinkedin className="chartIcon" />
    </IconArea>
  );
};
export const YoutubeIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaYoutube className="chartIcon" />
    </IconArea>
  );
};
export const TwitterIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaTwitter className="chartIcon" />
    </IconArea>
  );
};
export const FacebookIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaFacebookF className="chartIcon" />
    </IconArea>
  );
};
export const InstagramIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <FaInstagram className="chartIcon" />
    </IconArea>
  );
};
export const MenuFoldIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <AiOutlineMenuFold className="chartIcon" />
    </IconArea>
  );
};
export const MenuUnfoldIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <AiOutlineMenuUnfold className="chartIcon" />
    </IconArea>
  );
};
export const CloseIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <AiOutlineClose className="chartIcon" />
    </IconArea>
  );
};
export const GooglemapsIcon = ({ width, height, color }) => {
  return (
    <IconArea width={width} height={height} color={color}>
      <SiGooglemaps className="chartIcon" />
    </IconArea>
  );
};

const IconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .chartIcon {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    color: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
