import React from "react";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import AboutMe from "../components/aboutMe/AboutMe";
import Education from "../components/edu/Education";
import Skills from "../components/skills/Skills";
import Experiences from "../components/experiences/Experiences";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import ScrollTopBtn from "../components/ScrollTopBtn";

const HomePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Header />
      <Home />
      <AboutMe />
      <Education />
      <Skills />
      <Experiences />
      <Portfolio />
      <Contact />
      <ScrollTopBtn toHeader={"/#header"} />

      <Footer />
    </div>
  );
};

export default HomePage;
