import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

// import mainLogo from "../../images/mainLogo.png";

import {
  AngleRightIcon,
  // PhoneIcon,
  EnvelopeIcon,
  GithubIcon,
  KaggleIcon,
  LinkedinIcon,
  YoutubeIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
} from "../standard/Icons";

import Commitments from "./Commitments";
import FooterBottom from "./FooterBottom";

function Footer() {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [footerWidth, setFooterWidth] = useState(false);
  // const [footerHeight, setFooterHeight] = useState(false);
  const [footerMenusGridCols, setFooterMenusFridCols] = useState(false);
  const [footerMenusPaddingTop, setFooterMenusPaddingTop] = useState(false);
  const [footerBoxWidth, setFooterBoxWidth] = useState(false);
  const [footerBoxHeight, setFooterBoxHeight] = useState(false);
  const [footerBoxHeaderH, setFooterBoxHeaderH] = useState(false);
  const [footerMenuItemsGap, setFooterMenuItemsGap] = useState(false);

  // const [footerMenusBoxHeight, setFooterMenusBoxHeight] = useState("20vw");
  const [infoFS, setInfoFS] = useState(false);
  const [infoHeight, setInfoHeight] = useState("2.5vw");
  const [infoIconSize, setInfoIconSize] = useState("2.5vw");
  const [socialIconLinkSize, setSocialIconLinkSize] = useState("2.4vw");
  const [socialIconsGap, setSocialIconsGap] = useState("1.0vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setFooterWidth("98vw");
      // setFooterHeight("110vw");
      setFooterMenusFridCols(2);
      setFooterBoxWidth("47vw");
      setFooterBoxHeight("53vw");
      setFooterMenusPaddingTop("1vw");
      setFooterBoxHeaderH("5vw");
      setFooterMenuItemsGap("1.0vw");

      // setFooterMenusBoxHeight("39vw");
      setInfoFS("3.4vw");
      setInfoHeight("6.0vw");
      setInfoIconSize("3.2vw");
      setSocialIconLinkSize("5.0vw");
      setSocialIconsGap("2.0vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setFooterWidth("92vw");
      // setFooterHeight("27vw");
      setFooterMenusFridCols(4);
      setFooterBoxWidth("23vw");
      setFooterBoxHeight("100%");
      setFooterMenusPaddingTop("1vw");
      setFooterBoxHeaderH("2.5vw");
      setFooterMenuItemsGap(".2vw");

      // setFooterMenusBoxHeight("30vw");
      setInfoFS("1.7vw");
      setInfoHeight("3.0vw");
      setInfoIconSize("1.7vw");
      setSocialIconLinkSize("2.5vw");
      setSocialIconsGap("1.0vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setFooterWidth("92vw");
      // setFooterHeight("30vw");
      setFooterMenusFridCols(4);
      setFooterBoxWidth("23vw");
      setFooterBoxHeight("100%");
      setFooterMenusPaddingTop("1vw");
      setFooterBoxHeaderH("2.5vw");
      setFooterMenuItemsGap(".1vw");

      // setFooterMenusBoxHeight("30vw");
      setInfoFS("1.6vw");
      setInfoHeight("3.3vw");
      setInfoIconSize("1.8vw");
      setSocialIconLinkSize("2.8vw");
      setSocialIconsGap("1.0vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setFooterWidth("92vw");
      // setFooterHeight("22vw");
      setFooterMenusFridCols(4);
      setFooterBoxWidth("22vw");
      setFooterBoxHeight("100%");
      setFooterMenusPaddingTop("1vw");
      setFooterBoxHeaderH("2.8vw");
      setFooterMenuItemsGap(".4vw");

      // setFooterMenusBoxHeight("22vw");
      setInfoFS("1.4vw");
      setInfoHeight("3.0vw");
      setInfoIconSize("1.6vw");
      setSocialIconLinkSize("2.6vw");
      setSocialIconsGap("1.0vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setFooterWidth("92vw");
      // setFooterHeight("20vw");
      setFooterMenusFridCols(4);
      setFooterBoxWidth("22vw");
      setFooterBoxHeight("100%");
      setFooterMenusPaddingTop("1vw");
      setFooterBoxHeaderH("2.8vw");
      setFooterMenuItemsGap(".5vw");

      // setFooterMenusBoxHeight("22vw");
      setInfoFS("1.3vw");
      setInfoHeight("2.7vw");
      setInfoIconSize("1.6vw");
      setSocialIconLinkSize("2.6vw");
      setSocialIconsGap("1.0vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setFooterWidth("1400px");
      // setFooterHeight("17rem");
      setFooterMenusFridCols(4);
      setFooterBoxWidth("340px");
      setFooterBoxHeight("100%");
      setFooterMenusPaddingTop("1rem");
      setFooterBoxHeaderH("2rem");
      setFooterMenuItemsGap(".5rem");

      // setFooterMenusBoxHeight("18rem");
      setInfoFS("1.2rem");
      setInfoHeight("2.5rem");
      setInfoIconSize("1.3rem");
      setSocialIconLinkSize("2.3rem");
      setSocialIconsGap("1.0rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <FooterArea
      className="footer"
      id="footer"
      mainColor={mainColor}
      mainColor2={mainColor2}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      footerBoxWidth={footerBoxWidth}
      footerBoxHeight={footerBoxHeight}
      footerMenusPaddingTop={footerMenusPaddingTop}
      footerBoxHeaderH={footerBoxHeaderH}
      footerMenuItemsGap={footerMenuItemsGap}
      // footerMenusBoxHeight={footerMenusBoxHeight}
      infoFS={infoFS}
      infoHeight={infoHeight}
      socialIconLinkSize={socialIconLinkSize}
      socialIconsGap={socialIconsGap}
    >
      <Commitments />

      <div
        className="footerMenus"
        style={{
          gridTemplateColumns: `repeat(${footerMenusGridCols}, 1fr)`,
          width: footerWidth,
          // height: footerHeight,
        }}
      >
        <div className="box">
          <div className="footerInfo">
            {/* <div className="logo">
              <Link to="/">
                <img src={mainLogo} alt="onur karagüler logo" />
              </Link>
            </div> */}

            <div className="infos">
              <a href="mailto:onurkaraguler@hotmail.com" className="info">
                <div className="iconArea">
                  <EnvelopeIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </div>
                <span>{"onurkaraguler@hotmail.com"}</span>
              </a>

              <a
                href="https://www.linkedin.com/in/onurkaraguler/"
                target="_blank"
                rel="noreferrer"
                className="info"
              >
                <div className="iconArea">
                  <LinkedinIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </div>
                <span>{"LinkedIn: onurkaraguler"}</span>
              </a>
              <a
                href="https://github.com/OnurKaraguler?tab=repositories"
                target="_blank"
                rel="noreferrer"
                className="info"
              >
                <div className="iconArea">
                  <GithubIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </div>
                <span>{"GitHub: OnurKaraguler"}</span>
              </a>
              <a
                href="https://www.kaggle.com/onurkaraguler"
                target="_blank"
                rel="noreferrer"
                className="info"
              >
                <div className="iconArea">
                  <KaggleIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </div>
                <span>{"Kaggle: onurkaraguler"}</span>
              </a>
            </div>

            <div className="socialLinks">
              <div className="header">Social Profiles:</div>

              <div className="icons">
                <a
                  href="https://www.youtube.com/channel/UCFjIQycch3JwHFZi_57idVw"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <YoutubeIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </a>
                <a
                  href="https://twitter.com/karaguler_onur"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <TwitterIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </a>

                <a
                  href="https://www.facebook.com/onur.karaguler.50/"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <FacebookIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </a>

                <a
                  href="https://www.instagram.com/onur_karaguler/"
                  target="_blank"
                  rel="noreferrer"
                  className="iconLink"
                >
                  <InstagramIcon
                    width={infoIconSize}
                    height={infoIconSize}
                    color={darkgray}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="box">
          <div className="menu">
            <h3 className="header">Quick Access</h3>
            <ul className="items">
              <li>
                <HashLink smooth to="/#header">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/#aboutme"
                  onClick={() => {
                    // categoriesBtnClicked();
                  }}
                >
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  About Me
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#edu">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Education
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#skills">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Skills
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#experiences">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Experiences
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#portfolio">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Portfolio
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#contact">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="box">
          <div className="menu">
            <h3 className="header">My Skills</h3>
            <ul className="items">
              <li>
                <HashLink
                  smooth
                  to="/#skills"
                  onClick={() => {
                    // categoriesBtnClicked(0);
                  }}
                >
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Web Development (React, JavaScript, CSS (with Sass), HTML)
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/#skills"
                  onClick={() => {
                    // categoriesBtnClicked(1);
                  }}
                >
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Data Science (Python, Pandas, Scikit-learn, Matplotlib, MySQL)
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#skills">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Web Scrapping (Selenium, Beautiful Soup , Requests)
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#skills">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Python Programmer (Python, PyQt5)
                </HashLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="box">
          <div className="menu">
            <h3 className="header">My Experiences</h3>
            <ul className="items">
              <li>
                <HashLink smooth to="/#experiences">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Web/React Developer
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#experiences">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Data Scientist
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#experiences">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Research and Development Manager
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#experiences">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Research and Development Team Leader
                </HashLink>
              </li>

              <li>
                <HashLink smooth to="/#experiences">
                  <span className="arrow">
                    <AngleRightIcon />
                  </span>
                  Product Design Group Leader
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <FooterBottom />
    </FooterArea>
  );
}

const FooterArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footerMenus {
    padding-top: ${(props) => props.footerMenusPaddingTop};
    display: grid;
    grid-row-gap: 0.5vw;
    grid-column-gap: 0.5vw;

    .box {
      width: ${(props) => props.footerBoxWidth};
      display: flex;
      flex-direction: column;
      align-items: center;

      /* place-self: center; // center the items in gridbox */

      .footerInfo {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .logo {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            user-select: none;
          }
        }

        .infos {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .info {
            height: ${(props) => props.infoHeight};
            width: 100%;
            display: flex;
            align-items: center;
            gap: 0.5vw;

            &:hover {
              span {
                color: ${(props) => props.mainColor};
              }
            }

            .iconArea {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            span {
              color: ${(props) => props.darkgray};
              font-size: ${(props) => props.infoFS};
            }
          }
        }

        .socialLinks {
          display: flex;
          flex-direction: column;

          .header {
            font-size: ${(props) => props.infoFS};
            font-weight: 400;
            display: flex;
            align-items: center;
          }

          .icons {
            display: flex;
            align-items: center;
            gap: ${(props) => props.socialIconsGap};

            .iconLink {
              height: ${(props) => props.socialIconLinkSize};
              width: ${(props) => props.socialIconLinkSize};
              color: ${(props) => props.darkgray};
              border: 0.1vw solid ${(props) => props.darkgray};
              border-radius: 50%;

              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                height: ${(props) => props.socialIconSize};
                width: ${(props) => props.socialIconSize};
              }
              &:hover {
                border: 0.1vw solid ${(props) => props.mainColor};
                .icon {
                  color: ${(props) => props.mainColor};
                }
              }
            }
          }
        }
      }

      .menu {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .header {
          width: 100%;
          height: ${(props) => props.footerBoxHeaderH};
          font-size: calc(${(props) => props.infoFS} + 0.2vw);
          font-weight: 600;
          color: ${(props) => props.darkgray};
        }

        .items {
          display: flex;
          flex-direction: column;
          gap: ${(props) => props.footerMenuItemsGap};

          li {
            a {
              position: relative;
              color: ${(props) => props.darkgray};
              font-size: ${(props) => props.infoFS};

              :hover {
                ::before {
                  content: "";
                  border-bottom: 0.1vw solid ${(props) => props.mainColor};
                  width: 90%;
                  position: absolute;
                  bottom: -0.2vw;
                  left: 1.4vw;
                }
              }
            }

            span.arrow {
              display: inline-block;
              margin-right: 0.3rem;
            }
          }
        }
      }
    }
  }
`;

export default Footer;
