import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import onur from "../../images/onur.jpeg";
import Image from "../standard/Image";

const AboutMe = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  // const [containerHeight, setContainerHeight] = useState("900px");
  const [imageWidth, setImageWidth] = useState(false);
  const [headerHeight, setHeaderHeight] = useState("13rem");
  const [headerFS, setHeaderFS] = useState("3rem");
  const [boxWidth, setBoxWidth] = useState(false);
  const [imageHeight, setImageHeight] = useState("32rem");
  const [contentWidth, setContentWidth] = useState(false);
  const [contentPaddingLeft, setContentPaddingLeft] = useState("3rem");
  const [contentHeaderFS, setContentHeaderFS] = useState("2.5rem");
  const [contentHeaderPadding, setContentHeaderPadding] =
    useState("0 0 0.7rem 0");
  const [contentInfoFS, setContentInfoFS] = useState("2.0rem");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      // setContainerHeight("200vw");
      setHeaderHeight("20vw");
      setHeaderFS("8vw");
      setImageWidth("100%");
      setBoxWidth("96%");
      setImageHeight("65vw");
      setContentWidth("100%");
      setContentPaddingLeft("0vw");
      setContentHeaderFS("1.4rem");
      setContentHeaderPadding("0.7rem 0 0.7rem 0");
      setContentInfoFS("1.2rem");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      // setContainerHeight("55vw");
      setHeaderHeight("13vw");
      setHeaderFS("4vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setImageHeight("40vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.7vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.2vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      // setContainerHeight("50vw");
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setImageHeight("34vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.5vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.0vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      // setContainerHeight("50vw");
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setImageHeight("33vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.5vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.0vw");
    }
    // computer
    else if (windowWidth < 1400) {
      // setContainerHeight("57vw");
      setHeaderHeight("15vw");
      setHeaderFS("4.6vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setImageHeight("39vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.5vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.2vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      // setContainerHeight("800px");
      setHeaderHeight("13rem");
      setHeaderFS("4rem");
      setImageWidth("40%");
      setBoxWidth("100%");
      setImageHeight("34rem");
      setContentWidth("60%");
      setContentPaddingLeft("2rem");
      setContentHeaderFS("2.5rem");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.0rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContainerArea
      id="aboutme" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      headerHeight={headerHeight}
      headerFS={headerFS}
      imageWidth={imageWidth}
      boxWidth={boxWidth}
      imageHeight={imageHeight}
      contentWidth={contentWidth}
      contentPaddingLeft={contentPaddingLeft}
      contentHeaderFS={contentHeaderFS}
      contentHeaderPadding={contentHeaderPadding}
      contentInfoFS={contentInfoFS}
      style={
        {
          // height: containerHeight,
        }
      }
    >
      <div
        className="main"
        style={{
          width: containerWidth,
          // flexDirection:
          //   windowHeight < 850 && windowWidth < 450 ? "column" : "row",
        }}
      >
        <div className="header">
          <h1 className="heading"> ABOUT ME</h1>
        </div>
        <div className="box-container">
          <div
            className="box"
            style={{
              padding: windowWidth < 550 && "0 0.5rem",
            }}
          >
            <Image
              className={"image"}
              width={"100%"}
              // height={"100%"}
              image={onur}
              info={"onur karagüler about me image"}
            />

            <div className="content">
              <h1 className="contentHeader">
                Transition to Web Development ...
              </h1>
              <p className="contentInfo">
                I am a Front-end Developer deeply committed to my work with
                great motivation and passion. 3.5 years ago, I redirected my
                career from mechanical engineering to programming and web
                development. I possess a strong knowledge of HTML, CSS, and
                JavaScript, and I am proficient in using popular front-end
                frameworks like React. Furthermore, I have developed and
                successfully deployed high-performance web applications using
                Node.js and Next.js 13. My skills, experience, and passion for
                web development make a significant contribution to the team I
                join.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    .header {
      display: block;
      width: 100%;
      height: ${(props) => props.headerHeight};

      display: flex;
      justify-content: center;
      align-items: center;

      .heading {
        text-align: center;
        color: ${(props) => props.darkgray};
        font-size: ${(props) => props.headerFS};

        /* span {
          color: ${(props) => props.darkgray};
          text-transform: uppercase;
        } */
      }
    }

    .box-container {
      width: 100%;

      display: flex;
      /* align-items: flex-start; */
      justify-content: center;

      .box {
        /* background-color: ${(props) => props.white}; */
        /* width: ${(props) => props.boxWidth}; */
        width: 100%;
        height: 100%;

        display: flex;
        /* align-items: flex-start; */
        justify-content: center;
        flex-wrap: wrap;

        .image {
          width: ${(props) => props.imageWidth};
          height: ${(props) => props.imageHeight};
          /* min-width: 230px; */

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 0.5rem;

          img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            object-fit: cover;
          }
        }

        .content {
          width: ${(props) => props.contentWidth};
          padding-left: ${(props) => props.contentPaddingLeft};

          .contentHeader {
            font-size: ${(props) => props.contentHeaderFS};
            padding: ${(props) => props.contentHeaderPadding};
          }

          .contentInfo {
            font-size: ${(props) => props.contentInfoFS};
            text-align: justify;
            text-indent: 3rem;
          }
        }
      }
    }
  }
`;

export default AboutMe;
