import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBrowserSize,
  setContainerWidth,
} from "../components/stores/generalSlice";

const ResponsivePage = () => {
  const dispatch = useDispatch();

  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      dispatch(setContainerWidth("100vw"));
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      dispatch(setContainerWidth("92vw"));
    }
    // tablet portrait
    else if (windowWidth < 850) {
      dispatch(setContainerWidth("92vw"));
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      dispatch(setContainerWidth("92vw"));
    }
    // computer
    else if (windowWidth < 1400) {
      dispatch(setContainerWidth("92vw"));
    }
    // computer
    else if (windowWidth >= 1400) {
      dispatch(setContainerWidth("1400px"));
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    const handleResize = () =>
      dispatch(
        setBrowserSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        })
      );

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};

export default ResponsivePage;
