import React from "react";
import styled from "styled-components";

const Image = ({
  className,
  width,
  height,
  image,
  info,
  bgColor,
  verticalAllign,
  horizontalAllign,
}) => {
  return (
    <ImageStyle
      className={className ? className : "image"}
      width={width}
      height={height}
      bgColor={bgColor}
      verticalAllign={verticalAllign}
      horizontalAllign={horizontalAllign}
      style={{
        alignItems: verticalAllign ? verticalAllign : "center",
        justifyContent: horizontalAllign ? horizontalAllign : "center",
      }}
    >
      <img src={image} alt={info} />
    </ImageStyle>
  );
};

const ImageStyle = styled.div`
  background-color: ${(props) => props.bgColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  object-fit: cover;
  overflow: hidden;

  display: flex;

  img {
    position: absolute;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    user-select: none;
  }
`;

export default Image;
