import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Image from "../standard/Image";
import programming from "../../images/programming.jpeg";
import engineering from "../../images/engineering.jpeg";
import languages from "../../images/languages.jpeg";
import web from "../../images/web.jpeg";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const Skills = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [headerHeight, setHeaderHeight] = useState("13rem");
  const [headerFS, setHeaderFS] = useState("3rem");
  const [gridCols, setGridCols] = useState(3);

  const [imageWidth, setImageWidth] = useState("90%");
  const [imageHeight, setImageHeight] = useState("32rem");
  const [contentHeaderFS, setContentHeaderFS] = useState("2.3rem");
  const [itemHeadNameFS, setItemHeadNameFS] = useState("2.0rem");
  const [itemNameFS, setItemNameFS] = useState("1.7rem");
  const [starFS, setStarFS] = useState("1.6rem");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setHeaderHeight("20vw");
      setHeaderFS("8vw");
      setGridCols(1);
      setImageWidth("80vw");
      setImageHeight("45vw");
      setContentHeaderFS("8.0vw");
      setItemHeadNameFS("6.0vw");
      setItemNameFS("5.0vw");
      setStarFS("5.0vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setHeaderHeight("13vw");
      setHeaderFS("4vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("9vw");
      setContentHeaderFS("2.2vw");
      setItemHeadNameFS("1.8vw");
      setItemNameFS("1.5vw");
      setStarFS("1.7vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("9vw");
      setContentHeaderFS("2.2vw");
      setItemHeadNameFS("1.8vw");
      setItemNameFS("1.6vw");
      setStarFS("1.8vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setGridCols(3);
      setImageWidth("20vw");
      setImageHeight("10vw");
      setContentHeaderFS("2.3vw");
      setItemHeadNameFS("2.0vw");
      setItemNameFS("1.7vw");
      setStarFS("1.8vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setHeaderHeight("15vw");
      setHeaderFS("4.6vw");
      setGridCols(3);
      setImageWidth("25vw");
      setImageHeight("14vw");
      setContentHeaderFS("2.3vw");
      setItemHeadNameFS("2.0vw");
      setItemNameFS("1.7vw");
      setStarFS("1.8vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setHeaderHeight("13rem");
      setHeaderFS("4rem");
      setGridCols(3);
      setImageWidth("350px");
      setImageHeight("200px");
      setContentHeaderFS("2.0rem");
      setItemHeadNameFS("1.7rem");
      setItemNameFS("1.5rem");
      setStarFS("1.6rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContainerArea
      id="skills" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      headerHeight={headerHeight}
      headerFS={headerFS}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      contentHeaderFS={contentHeaderFS}
      itemHeadNameFS={itemHeadNameFS}
      itemNameFS={itemNameFS}
      starFS={starFS}
      style={
        {
          // height: containerHeight,
        }
      }
    >
      <div
        className="main"
        style={{
          width: containerWidth,
        }}
      >
        <div className="header">
          <h1 className="heading">
            <span>MY SKILLS</span>
          </h1>
        </div>

        <div
          className="boxes"
          style={{
            gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
            width: containerWidth,
            padding: windowWidth < 550 && "0 0.5rem",
          }}
        >
          <div className="box">
            <div className="image">
              <Image
                className={"image"}
                // width={"100%"}
                height={"100%"}
                image={web}
                info={"onur karagüler programming"}
              />
            </div>

            <div className="content">
              <h1 className="contentHeader">Web Development</h1>
              <div className="item-head">
                <h2 className="itemHeadName">JavaScript</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Node.js/Next.js 13</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">React</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Redux</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">React Router DOM</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>

              <div className="item-head">
                <h2 className="itemHeadName">CSS</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Tailwindcss</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Sass</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Styled Components</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>

              <div className="item">
                <h3 className="itemName">Responsive Design</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>

              <div className="item-head">
                <h2 className="itemHeadName">HTML</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>

              <div className="item-head">
                <h2 className="itemHeadName">Git</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>

              <div className="item-head">
                <h2 className="itemHeadName">PHP</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>

              <div className="item-head">
                <h2 className="itemHeadName">Databases</h2>
                <div className="stars"></div>
              </div>
              <div className="item">
                <h3 className="itemName">MySQL</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Firebase</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="image">
              <Image
                className={"image"}
                // width={"100%"}
                height={"100%"}
                image={programming}
                info={"onur karagüler programming"}
              />
            </div>

            <div className="content">
              <h1 className="contentHeader">Data Science</h1>
              <div className="item-head">
                <h2 className="itemHeadName">Python</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Pandas</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Scikit-learn</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">TensorFlow</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Matplotlib</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Plotly</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Selenium</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStarHalfAlt className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Beautiful Soup</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStarHalfAlt className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">Requests</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStarHalfAlt className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">PyQt5</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStarHalfAlt className="star" />
                </div>
              </div>
              <div className="item">
                <h3 className="itemName">PySpark</h3>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="image">
              <Image
                className={"image"}
                // width={"100%"}
                height={"100%"}
                image={languages}
                info={"onur karagüler languages"}
              />
            </div>

            <div className="content">
              <h1 className="contentHeader">Languages</h1>

              <div className="item-lang">
                <h2>English</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
              <div className="item-lang">
                <h2>German</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
              <div className="item-lang">
                <h2>Italian</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                  <FaRegStar className="star" />
                </div>
              </div>
            </div>

            <div className="image">
              <Image
                className={"image"}
                // width={"100%"}
                height={"100%"}
                image={engineering}
                info={"onur karagüler engineering"}
              />
            </div>

            <div className="content">
              <h1 className="contentHeader">Engineering</h1>

              <div className="item-lang">
                <h2>Solidworks</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>

              <div className="item-lang">
                <h2>Cimatron</h2>
                <div className="stars">
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                  <FaStar className="star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  /* background-color: ${(props) => props.mainColor4}; */
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    .header {
      width: 100%;
      height: ${(props) => props.headerHeight};

      display: flex;
      justify-content: center;
      align-items: center;

      .heading {
        text-align: center;
        font-size: ${(props) => props.headerFS};
      }
    }

    .boxes {
      width: 100%;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 1rem;

      .box {
        padding: 1rem 0;
        border: 0.2rem solid ${(props) => props.mainColor};
        border-radius: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;

        .image {
          width: ${(props) => props.imageWidth};
          height: ${(props) => props.imageHeight};

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content {
          width: 100%;
          // max-width: 400px;
          padding: 0 1rem;

          .contentHeader {
            color: ${(props) => props.mainColor3};
            font-size: ${(props) => props.contentHeaderFS};
            padding: 1rem 0;
          }

          .itemHeadName {
            color: ${(props) => props.darkgray};
            font-size: ${(props) => props.itemHeadNameFS};
          }

          .itemName {
            color: ${(props) => props.darkgray};
            font-size: ${(props) => props.itemNameFS};
            padding-left: 1rem;
          }

          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &:hover {
              border-bottom: 0.1rem solid ${(props) => props.mainColor3};
              cursor: pointer;
            }

            &-head {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              /* border-bottom: 0.2vh solid ${(props) => props.lightgray}; */
              &:hover {
                // color: $pink;
                border-bottom: 0.1rem solid ${(props) => props.mainColor3};
                cursor: pointer;
              }
            }

            &-lang {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              &:hover {
                border-bottom: 0.1rem solid ${(props) => props.mainColor3};
                cursor: pointer;
              }

              h2 {
                color: ${(props) => props.mainColor};
                font-size: ${(props) => props.itemNameFS};
              }
            }
          }

          .stars {
            .star {
              font-size: ${(props) => props.starFS};
              color: ${(props) => props.darkgray};
            }
          }
        }
      }
    }
  }
`;

export default Skills;
