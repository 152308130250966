import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import mainLogo from "../../images/mainLogo.png";
import SideLinksContainer from "./SideLinksContainer";
import HeaderBottom from "./HeaderBottom";

import {
  LinkedinIcon,
  GithubIcon,
  KaggleIcon,
  YoutubeIcon,
  MenuFoldIcon,
} from "../standard/Icons";

import {
  setHeaderBottomState,
  setSideLinksContState,
} from "../stores/generalSlice";

const Header = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  const dispatch = useDispatch();

  const sideLinksContShow = () => {
    dispatch(setSideLinksContState(true));
  };
  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [containerHeight, setContainerHeight] = useState("100px");

  const [headerBottomPosition, setHeaderBottomPosition] = useState(7);

  const [navbarFS, setNavbarFS] = useState("2.1vw");
  const [navbarGap, setNavbarGap] = useState("1.2vw");
  const [topIconGap, setTopIconGap] = useState("1vw");
  const [topIconSize, setTopIconSize] = useState("2.5vw");

  const [menuIconSize, setMenuIconSize] = useState("6vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setContainerHeight("16vw");
      setHeaderBottomPosition(16);
      setMenuIconSize("10vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setContainerHeight("10vw");
      setHeaderBottomPosition(10);
      setMenuIconSize("6vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setContainerHeight("8vw");
      setHeaderBottomPosition(8);
      setMenuIconSize("5.0vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setContainerHeight("7vw");
      setHeaderBottomPosition(7);

      setNavbarFS("2.0vw");
      setNavbarGap("1.5vw");
      setTopIconGap("1vw");
      setTopIconSize("1.5vw");

      // setSocialLinksSize("4.5vw");
      // setSocialIconsSize("3.5vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setContainerHeight("7vw");
      setHeaderBottomPosition(7);

      setNavbarFS("2.0vw");
      setNavbarGap("1.5vw");
      setTopIconGap("1vw");
      setTopIconSize("1.5vw");

      // setSocialLinksSize("2.0rem");
      // setSocialIconsSize("1.5rem");
    }
    // computer
    else if (windowWidth >= 1400) {
      setContainerHeight("100px");
      setHeaderBottomPosition(7);
      setMenuIconSize("2.5vw");

      setNavbarFS("1.8rem");
      setNavbarGap("1.4rem");
      setTopIconGap(".8rem");
      setTopIconSize("1.3rem");

      // setSocialLinksSize("2.0rem");
      // setSocialIconsSize("1.5rem");
    }
  }, [windowWidth, windowHeight]);

  //-----------------------\\
  //*--- Scroll position ---\\
  //-----------------------\\
  const [scrollPosition, setScrollPosition] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 1400) {
      // convert from vw to px
      if (scrollPosition >= 100) {
        dispatch(setHeaderBottomState(true));
      } else {
        dispatch(setHeaderBottomState(false));
      }
    } else {
      // convert from vw to px
      if (scrollPosition >= (headerBottomPosition * window.innerWidth) / 100) {
        dispatch(setHeaderBottomState(true));
      } else {
        dispatch(setHeaderBottomState(false));
      }
    }
  }, [headerBottomPosition, scrollPosition]);

  return (
    <>
      <HeaderArea
        id="header" // for HashLink
        mainColor={mainColor}
        mainColor2={mainColor2}
        mainColor3={mainColor3}
        mainColor4={mainColor4}
        lightgray={lightgray}
        gray={gray}
        darkgray={darkgray}
        white={white}
        containerHeight={containerHeight}
        navbarFS={navbarFS}
        navbarGap={navbarGap}
        topIconGap={topIconGap}
      >
        <div
          className="headerTop"
          style={{
            width: containerWidth,
          }}
        >
          <div className="topLeft">
            <Link className="logo" to="/">
              <img src={mainLogo} alt="logo" />
            </Link>
          </div>
          <div className="topRight">
            {windowWidth > 850 ? (
              <>
                <div className="socialLinks">
                  <div className="icons">
                    <a
                      href="https://www.linkedin.com/in/onurkaraguler/"
                      target="_blank"
                      rel="noreferrer"
                      className="info"
                    >
                      <div className="iconArea">
                        <LinkedinIcon
                          width={topIconSize}
                          height={topIconSize}
                        />
                      </div>
                    </a>
                    <a
                      href="https://github.com/OnurKaraguler?tab=repositories"
                      target="_blank"
                      rel="noreferrer"
                      className="info"
                    >
                      <div className="iconArea">
                        <GithubIcon width={topIconSize} height={topIconSize} />
                      </div>
                    </a>
                    <a
                      href="https://www.kaggle.com/onurkaraguler"
                      target="_blank"
                      rel="noreferrer"
                      className="info"
                    >
                      <div className="iconArea">
                        <KaggleIcon width={topIconSize} height={topIconSize} />
                      </div>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCFjIQycch3JwHFZi_57idVw"
                      target="_blank"
                      rel="noreferrer"
                      className="info"
                    >
                      <div className="iconArea">
                        <YoutubeIcon width={topIconSize} height={topIconSize} />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="navbar">
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    <span>Home</span>
                  </Link>

                  <HashLink smooth to="/#aboutme">
                    About Me
                  </HashLink>
                  <HashLink smooth to="/#edu">
                    Education
                  </HashLink>
                  <HashLink smooth to="/#skills">
                    Skills
                  </HashLink>
                  <HashLink smooth to="/#experiences">
                    Experiences
                  </HashLink>
                  <HashLink smooth to="/#portfolio">
                    Portfolio
                  </HashLink>
                  <HashLink smooth to="/#contact">
                    Contact
                  </HashLink>
                </div>
              </>
            ) : (
              <div className="iconArea" onClick={sideLinksContShow}>
                <MenuFoldIcon
                  width={menuIconSize}
                  height={menuIconSize}
                  color={mainColor2}
                />
              </div>
            )}
          </div>
        </div>
      </HeaderArea>
      <HeaderBottom />

      <SideLinksContainer />
    </>
  );
};

const HeaderArea = styled.div`
  background-color: ${(props) => props.mainColor};
  z-index: 10;
  top: 0;
  width: 100vw;
  height: ${(props) => props.containerHeight};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .headerTop {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .topLeft {
      width: 25%;
      height: 100%;

      display: flex;
      align-items: center;

      .logo {
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          user-select: none;
        }
      }
    }

    .topRight {
      width: 75%;
      height: 100%;
      padding: 0 1%;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      .socialLinks {
        width: 100%;
        height: 35%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .icons {
          display: flex;
          align-items: center;
          gap: ${(props) => props.topIconGap};
        }
      }

      .navbar {
        width: 100%;
        height: 65%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: ${(props) => props.navbarGap};

        a {
          font-size: ${(props) => props.navbarFS};
          font-weight: 500;
          color: ${(props) => props.lightgray};

          &:hover {
            color: ${(props) => props.mainColor3};
            transition: 0.2s linear;
          }
        }
      }

      .iconArea {
        color: ${(props) => props.darkgray};
        height: 100%;
        font-size: 1.4vw;
        cursor: pointer;
        color: ${(props) => props.mainColor2};

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: ${(props) => props.mainColor3};
          transition: 0.2s linear;
        }
      }
    }
  }

  .headerBottom {
    width: 100%;
  }
`;
export default Header;
