import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import useExperiences from "./useExperiences";

const Experiences = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [headerHeight, setHeaderHeight] = useState("13rem");
  const [headerFS, setHeaderFS] = useState("3rem");
  const [imageHeight, setImageHeight] = useState("32rem");
  const [contentWidth, setContentWidth] = useState("60%");
  const [contentPaddingLeft, setContentPaddingLeft] = useState("3rem");
  const [contentHeaderFS, setContentHeaderFS] = useState("2.5rem");
  const [contentHeaderPadding, setContentHeaderPadding] =
    useState("0 0 0.7rem 0");
  const [contentInfoFS, setContentInfoFS] = useState("2.0rem");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setHeaderHeight("20vw");
      setHeaderFS("8vw");
      setImageHeight("65vw");
      setContentWidth("100%");
      setContentPaddingLeft("0vw");
      setContentHeaderFS("1.4rem");
      setContentHeaderPadding("0.7rem 0 0.7rem 0");
      setContentInfoFS("1.2rem");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setHeaderHeight("13vw");
      setHeaderFS("4vw");
      setImageHeight("40vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.7vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.2vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setImageHeight("34vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.5vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.0vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setImageHeight("33vw");
      setContentWidth("60%");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.5vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.0vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setHeaderHeight("15vw");
      setHeaderFS("4.6vw");
      setImageHeight("39vw");
      setContentWidth("60vw");
      setContentPaddingLeft("3vw");
      setContentHeaderFS("2.9vw");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.3vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setHeaderHeight("13rem");
      setHeaderFS("4rem");
      setImageHeight("34rem");
      setContentWidth("800px");
      setContentPaddingLeft("3rem");
      setContentHeaderFS("2.5rem");
      setContentHeaderPadding("0 0 0.7rem 0");
      setContentInfoFS("2.0rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContainerArea
      id="experiences" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      headerHeight={headerHeight}
      headerFS={headerFS}
      imageHeight={imageHeight}
      contentWidth={contentWidth}
      contentPaddingLeft={contentPaddingLeft}
      contentHeaderFS={contentHeaderFS}
      contentHeaderPadding={contentHeaderPadding}
      contentInfoFS={contentInfoFS}
    >
      <div
        className="main"
        style={{
          width: containerWidth,
        }}
      >
        <div className="header">
          <h1 className="heading">MY EXPERIENCES</h1>
        </div>
        <div
          className="boxContainer"
          style={{
            padding: windowWidth < 550 && "0 0.5rem",
          }}
        >
          {useExperiences()}
        </div>
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  background-color: ${(props) => props.mainColor4};
  width: 100%;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    .header {
      display: block;
      width: 100%;
      height: ${(props) => props.headerHeight};

      display: flex;
      justify-content: center;
      align-items: center;

      .heading {
        text-align: center;
        color: ${(props) => props.mainColor2};
        font-size: ${(props) => props.headerFS};
      }
    }

    .boxContainer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export default Experiences;
