import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

const NextPrevArrow = ({
  className,
  next,
  switchSlide,
  size,
  borderThickness,
  hoverBgColor,
  bgColor,
  fillColor,
  hoverFillColor,
  borderColor,
  transformDist,
  opacity,
  leftDist,
  rightDist,
}) => {
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;

  return (
    <NextArrowArea
      className={className}
      size={size}
      hoverBgColor={hoverBgColor}
      bgColor={bgColor}
      fillColor={fillColor}
      hoverFillColor={hoverFillColor}
      borderThickness={borderThickness}
      borderColor={borderColor}
      transformDist={transformDist}
      opacity={opacity}
      onClick={() => (next ? switchSlide("next") : switchSlide("prev"))}
      style={{
        left: leftDist,
        right: rightDist,
      }}
    >
      {next ? (
        <svg className="next-button-icon nextPrevIcon" viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            className="arrow"
            transform="translate(100, 100) rotate(180) "
          ></path>
        </svg>
      ) : (
        <svg className="prev-button-icon nextPrevIcon" viewBox="0 0 100 100">
          <path
            d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
            className="arrow"
          ></path>
        </svg>
      )}
    </NextArrowArea>
  );
};

const NextArrowArea = styled.div`
  position: absolute;
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  top: 45%;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;

  opacity: ${(props) => props.opacity};
  transform: translateX(${(props) => props.transformDist});

  .nextPrevIcon {
    background-color: ${(props) => props.bgColor};
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 4px;

    fill: ${(props) => props.fillColor};
    border: ${(props) => props.borderThickness} solid
      ${(props) => props.borderColor};
    border-radius: 50%;
    user-select: none;
    path {
      stroke-width: 6vw;
    }
  }

  :hover {
    background-color: ${(props) => props.hoverBgColor};

    .nextPrevIcon {
      border: ${(props) => props.borderThickness} solid
        ${(props) => props.hoverBgColor};
      fill: ${(props) => props.hoverFillColor};
    }

    /* .nextPrevIcon {
      animation: prevNextAni 0.5s ease-in-out forwards;
      -webkit-animation: prevNextAni 0.5s ease-in-out forwards;

      @keyframes prevNextAni {
        100% {
          border: ${(props) => props.borderThickness} solid
            ${(props) => props.mainFillColor};
          fill: ${(props) => props.mainFillColor2};
        }
      }
    } */
  }
`;

export default NextPrevArrow;
