import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./components/stores/store";

import HomePage from "./pages/HomePage";
import ResponsivePage from "./components/ResponsivePage";

function App() {
  return (
    <Provider store={store}>
      {/* {server()} */}
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>

      <ResponsivePage />
    </Provider>
  );
}

export default App;
