import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  ReactIcon,
  WebsiteIcon,
  DataScienceIcon,
  PythonIcon,
} from "../standard/Icons";

const Commitments = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [companyCommitmentsHeight, setCompanyCommitmentsHeight] = useState(8);
  const [commitmentsWidth, setCommitmentsWidth] = useState(false);
  const [commitmentsGridCols, setCommitmentsFridCols] = useState(4);
  const [iconSize, setIconSize] = useState(3.5);
  const [commitmentsBoxWidth, setCommitmentsBoxWidth] = useState("20vw");
  const [commitmentsBoxHeight, setCommitmentsBoxHeight] = useState("7vw");
  const [contentHeaderFS, setContentHeaderFS] = useState("1.3vw");
  const [contentExplaneFS, setContentExplaneFS] = useState("1.1vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setCompanyCommitmentsHeight(27);
      setCommitmentsWidth("98vw");
      setCommitmentsFridCols(2);
      setIconSize(6);
      setCommitmentsBoxWidth("47vw");
      setCommitmentsBoxHeight("12vw");
      setContentHeaderFS("3.0vw");
      setContentExplaneFS("2.4vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setCompanyCommitmentsHeight(9);
      setCommitmentsWidth("92vw");
      setCommitmentsFridCols(4);
      setIconSize(4);
      setCommitmentsBoxWidth("22vw");
      setCommitmentsBoxHeight("9vw");
      setContentHeaderFS("1.5vw");
      setContentExplaneFS("1.3vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setCompanyCommitmentsHeight(10);
      setCommitmentsWidth("92vw");
      setCommitmentsFridCols(4);
      setIconSize(4);
      setCommitmentsBoxWidth("22vw");
      setCommitmentsBoxHeight("10vw");
      setContentHeaderFS("1.6vw");
      setContentExplaneFS("1.3vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setCompanyCommitmentsHeight(8);
      setCommitmentsWidth("92vw");
      setCommitmentsFridCols(4);
      setIconSize(4);
      setCommitmentsBoxWidth("22vw");
      setCommitmentsBoxHeight("8vw");
      setContentHeaderFS("1.3vw");
      setContentExplaneFS("1.1vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setCompanyCommitmentsHeight(8);
      setCommitmentsWidth("92vw");
      setCommitmentsFridCols(4);
      setIconSize(3.5);
      setCommitmentsBoxWidth("20vw");
      setCommitmentsBoxHeight("8vw");
      setContentHeaderFS("1.3vw");
      setContentExplaneFS("1.1vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setCompanyCommitmentsHeight(8);
      setCommitmentsWidth("1400px");
      setCommitmentsFridCols(4);
      setIconSize(3.5);
      setCommitmentsBoxWidth("20rem");
      setCommitmentsBoxHeight("8rem");
      setContentHeaderFS("1.3rem");
      setContentExplaneFS("1.1rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <FooterCommitmentsArea
      className="companyCommitments"
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      commitmentsBoxWidth={commitmentsBoxWidth}
      commitmentsBoxHeight={commitmentsBoxHeight}
      contentHeaderFS={contentHeaderFS}
      contentExplaneFS={contentExplaneFS}
      style={{
        height: `${companyCommitmentsHeight}vw`,
      }}
    >
      <div
        className="commitments"
        style={{
          width: commitmentsWidth,
          gridTemplateColumns: `repeat(${commitmentsGridCols}, 1fr)`,
        }}
      >
        <div className="box">
          <div className="icon">
            <WebsiteIcon
              width={`${iconSize}vw`}
              height={`${iconSize}vw`}
              color={mainColor2}
            />
          </div>
          <div className="content">
            <div className="contentHeader">Frontend Developer</div>
            <div className="contentExplane">
              Creating beautiful and functional websites is my passion.
            </div>
          </div>
        </div>

        <div className="box">
          <div className="icon">
            <ReactIcon
              width={`${iconSize}vw`}
              height={`${iconSize}vw`}
              color={mainColor2}
            />
          </div>
          <div className="content">
            <div className="contentHeader">React Developer</div>
            <div className="contentExplane">
              React is my toolkit for building stunning, interactive web apps.
            </div>
          </div>
        </div>

        <div className="box">
          <div className="icon">
            <DataScienceIcon
              width={`${iconSize}vw`}
              height={`${iconSize}vw`}
              color={mainColor2}
            />
          </div>
          <div className="content">
            <div className="contentHeader">Data Scientist</div>
            <div className="contentExplane">
              As a data scientist, I turn chaos into clarity.
            </div>
          </div>
        </div>

        <div className="box">
          <div className="icon">
            <PythonIcon
              width={`${iconSize}vw`}
              height={`${iconSize}vw`}
              color={mainColor2}
            />
          </div>
          <div className="content">
            <p className="contentHeader">Python Programmer</p>
            <p className="contentExplane">
              Python is my preferred language for turning ideas into reality.
            </p>
          </div>
        </div>
      </div>
    </FooterCommitmentsArea>
  );
};

const FooterCommitmentsArea = styled.div`
  background-color: ${(props) => props.mainColor4};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .commitments {
    display: grid;
    grid-row-gap: 1vw;
    grid-column-gap: 1vw;

    .box {
      width: ${(props) => props.commitmentsBoxWidth};
      height: ${(props) => props.commitmentsBoxHeight};
      display: flex;
      align-items: center;
      gap: 0.3vw;

      place-self: center; // center the items in gridbox

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.3vw;

        .contentHeader {
          width: 100%;
          color: ${(props) => props.mainColor2};
          font-size: ${(props) => props.contentHeaderFS};
          font-weight: 500;
          display: flex;
          align-items: center;
        }

        .contentExplane {
          width: 100%;
          color: ${(props) => props.mainColor2};
          font-size: ${(props) => props.contentExplaneFS};
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export default Commitments;
