import { createSlice } from "@reduxjs/toolkit";

export const generalSlice = createSlice({
  name: "general",
  initialState: {
    // mainColor: "#0A1C2C",
    mainColor: "hsl(195, 100%, 6%)", // main darkblue
    mainColor2: "hsl(197, 24%, 94%)", // verylightblue
    mainColor3: "hsl(350, 96%, 43%)", // darkred
    // mainColor3: "#ef233c", // red

    // mainColor: "hsl(235, 21%, 21%)",
    // mainColor2: "hsl(88, 63%, 90%)",
    // mainColor3: "hsl(333, 93%, 65%)", // text
    // mainColor4: "hsl(211, 55%, 30%)", // background2

    mainColor4: "hsl(218, 17%, 62%)", // lightblue
    mainColor4: "hsl(205, 100%, 17%)", // lightblue
    // mainColor4: "hsl(218, 17%, 62%)", // lightblue
    mainColor7: "hsl(353, 86%, 54%)", // red

    // mainColor2: "hsl(212, 54%, 75%)",
    // mainColor2: "#326AA8",
    // mainColor4: "#224B76",
    // mainColor: "#EE813C",
    // mainColor2: "#1E4C7D",
    orange: "#EE813C",
    lightgray: "#E2E2E2",
    gray: "#939393",
    darkgray: "#232323",
    white: "#fff",

    browserSize: {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    },

    headerBottomState: false,
    sideLinksContState: false,
    containerWidth: false,
    sliderSlidingState: false,
  },
  reducers: {
    setBrowserSize: (state, action) => {
      state.browserSize = action.payload;
    },
    setHeaderBottomState: (state, action) => {
      state.headerBottomState = action.payload;
    },
    setSideLinksContState: (state, action) => {
      state.sideLinksContState = action.payload;
    },
    setContainerWidth: (state, action) => {
      state.containerWidth = action.payload;
    },
    setSliderSlidingState: (state, action) => {
      state.sliderSlidingState = action.payload;
    },
  },
});

export const {
  setBrowserSize,
  setHeaderBottomState,
  setSideLinksContState,
  setContainerWidth,
  setSliderSlidingState,
} = generalSlice.actions;
export default generalSlice.reducer;
