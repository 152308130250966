import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import {
  LinkedinIcon,
  GithubIcon,
  KaggleIcon,
  YoutubeIcon,
  MenuFoldIcon,
} from "../standard/Icons";
import { setSideLinksContState } from "../stores/generalSlice";

const HeaderBottom = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  const dispatch = useDispatch();

  const headerBottomState = useSelector(
    (state) => state.generalStore.headerBottomState
  );

  const sideLinksContShow = () => {
    dispatch(setSideLinksContState(true));
  };

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [minHeight, setMinHeight] = useState("0.5vw");
  const [maxHeight, setMaxHeight] = useState("3vw");
  const [headerBottomFS, setHeaderBottomFS] = useState("2.1vw");
  const [headerBottomGap, setHeaderBottomGap] = useState("1.2vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setMinHeight("1.0vw");
      setMaxHeight("9.0vw");

      setHeaderBottomFS("4.5vw");
      setHeaderBottomGap("2.5vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setMinHeight("0.5vw");
      setMaxHeight("3.8vw");

      setHeaderBottomFS("1.9vw");
      setHeaderBottomGap("1.5vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setMinHeight("0.5vw");
      setMaxHeight("3.8vw");

      setHeaderBottomFS("1.9vw");
      setHeaderBottomGap("1.5vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setMinHeight("0.5vw");
      setMaxHeight("3.2vw");

      setHeaderBottomFS("1.7vw");
      setHeaderBottomGap("1.3vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setMinHeight("0.5vw");
      setMaxHeight("2.5vw");

      setHeaderBottomFS("1.3vw");
      setHeaderBottomGap("1.2vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setMinHeight("0.5rem");
      setMaxHeight("2.5rem");

      setHeaderBottomFS("1.2rem");
      setHeaderBottomGap("1.0rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <HeaderBottomArea
      // className="headerBottom"
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      headerBottomFS={headerBottomFS}
      headerBottomGap={headerBottomGap}
      style={{
        height: headerBottomState ? maxHeight : minHeight,
        position: headerBottomState && "fixed",
        top: headerBottomState && "0",
      }}
    >
      <div
        className="headerBottom"
        style={{
          width: containerWidth,
          padding: windowWidth < 550 && "0 .7rem",
        }}
      >
        <div className="headerBottomContainer">
          {headerBottomState && (
            <>
              <div className="infos">
                <a
                  href="https://www.linkedin.com/in/onurkaraguler/"
                  target="_blank"
                  rel="noreferrer"
                  className="info"
                >
                  <div className="iconArea">
                    <LinkedinIcon
                      width={headerBottomFS}
                      height={headerBottomFS}
                      color={mainColor2}
                    />
                  </div>
                </a>
                <a
                  href="https://github.com/OnurKaraguler?tab=repositories"
                  target="_blank"
                  rel="noreferrer"
                  className="info"
                >
                  <div className="iconArea">
                    <GithubIcon
                      width={headerBottomFS}
                      height={headerBottomFS}
                      color={mainColor2}
                    />
                  </div>
                </a>
                <a
                  href="https://www.kaggle.com/onurkaraguler"
                  target="_blank"
                  rel="noreferrer"
                  className="info"
                >
                  <div className="iconArea">
                    <KaggleIcon
                      width={headerBottomFS}
                      height={headerBottomFS}
                      color={mainColor2}
                    />
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCFjIQycch3JwHFZi_57idVw"
                  target="_blank"
                  rel="noreferrer"
                  className="info"
                >
                  <div className="iconArea">
                    <YoutubeIcon
                      width={headerBottomFS}
                      height={headerBottomFS}
                      color={mainColor2}
                    />
                  </div>
                </a>
              </div>

              {windowWidth < 550 ? (
                <div className="iconArea" onClick={sideLinksContShow}>
                  <MenuFoldIcon
                    width={windowWidth < 550 ? "6vw" : headerBottomFS}
                    height={windowWidth < 550 ? "6vw" : headerBottomFS}
                    color={mainColor2}
                  />
                </div>
              ) : (
                <nav className="navbar">
                  <Link to="/" onClick={(e) => window.scrollTo(0, 0)}>
                    <span>Home</span>
                  </Link>
                  <HashLink smooth to="/#aboutme">
                    About Me
                  </HashLink>
                  <HashLink smooth to="/#edu">
                    Education
                  </HashLink>
                  <HashLink smooth to="/#skills">
                    Skills
                  </HashLink>
                  <HashLink smooth to="/#experiences">
                    Experiences
                  </HashLink>
                  <HashLink smooth to="/#portfolio">
                    Portfolio
                  </HashLink>
                  <HashLink smooth to="/#contact">
                    Contact
                  </HashLink>
                </nav>
              )}
            </>
          )}
        </div>
      </div>
    </HeaderBottomArea>
  );
};

const HeaderBottomArea = styled.div`
  z-index: 10;
  background-color: ${(props) => props.mainColor3};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  width: 100%;

  .headerBottomContainer {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .infos {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${(props) => props.headerBottomGap};
      user-select: none;

      .info {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;

        .iconArea {
          width: ${(props) => props.iconSize};
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .icon {
            width: ${(props) => props.iconSize};
            height: ${(props) => props.iconSize};
            color: ${(props) => props.white};
          }
        }
      }
    }
    .navbar {
      /* background-color: lightcoral; */
      width: 100%;
      height: 65%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* justify-content: space-between; */
      gap: ${(props) => props.headerBottomGap};

      a {
        font-size: ${(props) => props.headerBottomFS};
        font-weight: 500;
        color: ${(props) => props.mainColor2};

        &:hover {
          color: ${(props) => props.mainColor};
          transition: 0.2s linear;
        }
      }
    }
  }
`;

export default HeaderBottom;
