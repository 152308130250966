import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import SendMessageForm from "./SendMessageForm";
import ContactInfo from "./ContactInfo";

const Contact = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const containerWidth = useSelector(
    (state) => state.generalStore.containerWidth
  );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  // const [containerHeight, setContainerHeight] = useState("900px");
  // const [infoHeight, setInfoHeight] = useState("18vw");
  const [headerHeight, setHeaderHeight] = useState("13rem");
  const [headerFS, setHeaderFS] = useState("3rem");
  const [flexDirection, setFlexDirection] = useState("row");
  const [titleElementWidth, setTitleElementWidth] = useState("41vw");
  const [titleElementHeight, setTitleElementHeight] = useState("3vw");
  const [titleElementFS, setTitleElementFS] = useState("3vw");
  const [contactFormHeight, setContactFormHeight] = useState("30vw");
  const [infoContainerHeight, setInfoContainerHeight] = useState("30vw");
  const [mapContainerHeight, setMapContainerHeight] = useState("30vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setHeaderHeight("20vw");
      setTitleElementWidth("100%");
      // setContainerHeight("200vw");
      // setInfoHeight("47vw");
      setHeaderFS("8vw");
      setFlexDirection("column");
      setContactFormHeight("78vw");
      setInfoContainerHeight("60vw");
      setTitleElementHeight("10vw");
      setTitleElementFS("4vw");
      setMapContainerHeight("40vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      // setContainerHeight("55vw");
      // setInfoHeight("29vw");
      setHeaderHeight("13vw");
      setHeaderFS("4vw");
      setFlexDirection("row");
      setContactFormHeight("40vw");
      setInfoContainerHeight("40vw");
      setTitleElementWidth("45vw");
      setTitleElementHeight("4vw");
      setTitleElementFS("1.8vw");
      setMapContainerHeight("30vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      // setContainerHeight("50vw");
      // setInfoHeight("29vw");
      setHeaderHeight("13vw");
      setHeaderFS("4.2vw");
      setHeaderFS("4.2vw");
      setFlexDirection("row");
      setContactFormHeight("40vw");
      setInfoContainerHeight("40vw");
      setTitleElementWidth("45vw");
      setTitleElementHeight("4vw");
      setTitleElementFS("1.8vw");
      setMapContainerHeight("30vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      // setContainerHeight("50vw");
      // setInfoHeight("29vw");
      setHeaderHeight("13vw");
      setFlexDirection("row");
      setContactFormHeight("40vw");
      setInfoContainerHeight("40vw");
      setTitleElementWidth("45vw");
      setTitleElementHeight("4vw");
      setTitleElementFS("1.8vw");
      setMapContainerHeight("30vw");
    }
    // computer
    else if (windowWidth < 1400) {
      // setContainerHeight("57vw");
      // setInfoHeight("29vw");
      setHeaderHeight("15vw");
      setHeaderFS("4.6vw");
      setFlexDirection("row");
      setContactFormHeight("40vw");
      setInfoContainerHeight("40vw");
      setTitleElementWidth("45vw");
      setTitleElementHeight("4vw");
      setTitleElementFS("1.8vw");
      setMapContainerHeight("28vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      // setContainerHeight("800px");
      // setInfoHeight("29rem");
      setHeaderHeight("13rem");
      setHeaderFS("4rem");
      setFlexDirection("row");
      setContactFormHeight("40rem");
      setInfoContainerHeight("40rem");
      setTitleElementWidth("43rem");
      setTitleElementHeight("3rem");
      setTitleElementFS("1.7rem");
      setMapContainerHeight("22rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContainerArea
      id="contact" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      headerFS={headerFS}
      headerHeight={headerHeight}
      titleElementWidth={titleElementWidth}
      titleElementHeight={titleElementHeight}
      titleElementFS={titleElementFS}
      contactFormHeight={contactFormHeight}
      infoContainerHeight={infoContainerHeight}
      // infoHeight={infoHeight}
      mapContainerHeight={mapContainerHeight}
      style={
        {
          // height: containerHeight,
        }
      }
    >
      <div
        className="main"
        style={{
          width: containerWidth,
        }}
      >
        <div className="header">
          <h1 className="heading">CONTACT ME</h1>
        </div>
        <div className="box-container">
          <div
            className="box"
            style={{
              padding: windowWidth < 550 && "0 0.5rem",
            }}
          >
            <div
              className="formInfoContainer"
              style={{ flexDirection: flexDirection }}
            >
              <SendMessageForm />
              <ContactInfo />
            </div>
          </div>
          <div className="mapContainer">
            <iframe
              title="Almital Mobilya Aksesuarları Lokasyonu"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.863878225205!2d28.710005615672426!3d40.98446142883846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa051c0d97db5%3A0x2b801ae4830d8a36!2zQ2loYW5naXIsIEfDvG5lciBTay4gTm86NywgMzQzMTAgQXZjxLFsYXIvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1661127254047!5m2!1str!2str"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  background-color: ${(props) => props.mainColor4};
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main {
    .header {
      display: block;
      width: 100%;
      height: ${(props) => props.headerHeight};

      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.mainColor2};

      .heading {
        text-align: center;
        font-size: ${(props) => props.headerFS};
      }
    }

    .box-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .box {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .formInfoContainer {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 1rem;

          .titleElement {
            color: ${(props) => props.mainColor2};
            width: ${(props) => props.titleElementWidth};
            height: ${(props) => props.titleElementHeight};
            font-size: ${(props) => props.titleElementFS};
            display: flex;
            align-items: center;
          }
        }
      }
      .mapContainer {
        width: 100vw;
        height: ${(props) => props.mapContainerHeight};
        margin-top: 1rem;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export default Contact;
