import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const FooterBottom = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [commitmentsWidth, setCommitmentsWidth] = useState(false);
  const [footerBottomHeight, setFooterBottomHeight] = useState(false);
  const [footerBottomFS, setFooterBottomFS] = useState(false);

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setCommitmentsWidth("98vw");
      setFooterBottomHeight("8vw");
      setFooterBottomFS("2.5vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setCommitmentsWidth("92vw");
      setFooterBottomHeight("5vw");
      setFooterBottomFS("1.8vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setCommitmentsWidth("92vw");
      setFooterBottomHeight("5vw");
      setFooterBottomFS("1.8vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setCommitmentsWidth("92vw");
      setFooterBottomHeight("3vw");
      setFooterBottomFS("1.4vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setCommitmentsWidth("92vw");
      setFooterBottomHeight("3vw");
      setFooterBottomFS("1.2vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setCommitmentsWidth("1400px");
      setFooterBottomHeight("3rem");
      setFooterBottomFS("1.2rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <FooterBottomArea
      className="footerBottom"
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      style={{
        width: commitmentsWidth,
        height: footerBottomHeight,
      }}
    >
      <div
        className="credit"
        style={{
          fontSize: footerBottomFS,
        }}
      >
        Copyright &copy; 2023 <span>Onur Karagüler</span>. All Rights Reserved.
      </div>

      <div
        className="developed"
        style={{
          fontSize: footerBottomFS,
        }}
      >
        Developed by{" "}
        <a
          href="https://onurkaraguler.github.io/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Onur Karagüler</span>
        </a>
      </div>
    </FooterBottomArea>
  );
};

const FooterBottomArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .credit {
    color: ${(props) => props.darkgray};
    font-weight: 300;

    span {
      color: ${(props) => props.mainColor3};
      font-weight: 500;
    }
  }

  .developed {
    font-size: 1vw;
    color: ${(props) => props.darkgray};

    span {
      color: ${(props) => props.mainColor3};
      font-weight: 500;

      &:hover {
        font-size: 1.05vw;
        transition: 0.2s linear;
      }
    }
  }
`;

export default FooterBottom;
