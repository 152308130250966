import { configureStore } from "@reduxjs/toolkit";
import generalReducer from "./generalSlice";

const store = configureStore({
  reducer: {
    generalStore: generalReducer,
  },
});

export default store;
