// https://www.youtube.com/watch?v=KvctnEZSvtM
import React from "react";
import styled from "styled-components";

const Button = ({
  type,
  variant,
  className,
  id,
  onClick,
  border,
  borderRadius,
  size,
  backgroundColor,
  color,
  fontSize,
  fontWeight,
  children,
  isActive,
  disabled,
}) => {
  return (
    <ButtonComponent
      type={type ? type : "button"}
      variant={variant}
      className={className ? className : "btn"}
      id={id}
      onClick={onClick}
      size={size}
      border={border}
      borderRadius={borderRadius}
      backgroundColor={disabled ? "gray" : backgroundColor}
      opacity={disabled ? "0.5" : "1"}
      pointerEvents={disabled ? "none" : "auto"}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </ButtonComponent>
  );
};

const ButtonComponent = styled.button`
  /* padding: 0 ${(props) => props.size[0]}; */
  width: ${(props) => props.size[0]};
  height: ${(props) => props.size[1]};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 0 ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color};
  pointer-events: ${(props) => props.pointerEvents};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};

  :hover {
    font-size: calc(${(props) => props.fontSize} + 0.05vw);
    transition: all 200ms ease;
  }

  /* @media (hover: none) {
    pointer-events: none;
  } */
`;

export default Button;
