import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import mainImg from "../../images/mainImg.png";
import Image from "../standard/Image";

const Home = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  const jobs = [
    "Front-end Developer",
    "React Developer",
    // "Data Scientist",
    // "Python Programmer",
  ];

  const [activeJobIndex, setActiveJobIndex] = useState(0);
  useEffect(() => {
    if (activeJobIndex === jobs.length) {
      setActiveJobIndex(0);
    }
  }, [activeJobIndex]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveJobIndex((activeJobIndex) => activeJobIndex + 1);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  // const containerWidth = useSelector(
  //   (state) => state.generalStore.containerWidth
  // );
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [containerWidth, setContainerWidth] = useState(false);
  const [containerHeight, setContainerHeight] = useState("700px");
  const [mainImgWidth, setMainImgWidth] = useState("600px");
  const [mainImgHeight, setMainImgHeight] = useState("100%");
  const [mainContentWidth, setMainContentWidth] = useState("600px");
  const [mainContentHeight, setMainContentHeight] = useState("100%");
  const [mainItemsPos, setMainItemsPos] = useState("absolute");
  const [mainImgLeft, setMainImgLeft] = useState("0");
  const [contentRight, setContentRight] = useState("0");
  const [contentTop, setContentTop] = useState("20%");
  const [contentGap, setContentGap] = useState("3rem");
  const [IamFS, setIamFS] = useState("1.1rem");
  const [nameFS, setNameFS] = useState("3rem");
  const [jobFS, setJobFS] = useState("3rem");
  const [nameAfterHeight, setNameAfterHeight] = useState("0.7rem");
  const [nameMarginBottom, setNameMarginBottom] = useState(false);

  useEffect(() => {
    // mobile portrait
    if (windowHeight < 850 && windowWidth < 450) {
      setContainerWidth("100vw");
      setContainerHeight("165vw");

      setMainImgWidth("100%");
      setMainImgHeight("115vw");

      setMainContentWidth("100%");
      setMainContentHeight("50vw");

      setMainItemsPos(false);
      setMainImgLeft("-5px");

      // setContentRight("+10px");
      // setContentTop("35%");
      setContentGap("2.0vw");
      setIamFS("6.0vw");
      setNameFS("11.0vw");
      setJobFS("8.0vw");
      setNameAfterHeight("1.4vw");
      setNameMarginBottom("3.0vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 450) {
      setContainerWidth("92vw");
      setContainerHeight("450px");

      setMainImgWidth("600px");
      setMainImgHeight("97%");
      setMainContentWidth("45vw");
      setMainContentHeight("22vw");

      setMainItemsPos("absolute");
      setMainImgLeft("-50px");

      setContentRight("+10px");
      setContentTop("35%");
      setContentGap("3.0vw");
      setIamFS("3.0vw");
      setNameFS("6.0vw");
      setJobFS("4.0vw");
      setNameAfterHeight("0.8vw");
      setNameMarginBottom("1.1vw");
    }
    // tablet portrait
    else if (
      windowWidth < 850 &&
      windowHeight < 1200 &&
      windowWidth >= 450 &&
      windowHeight >= 450
    ) {
      setContainerWidth("92vw");
      setContainerHeight("84vw");

      setMainImgWidth("72vw");
      setMainImgHeight("97%");
      setMainContentWidth("50vw");
      setMainContentHeight("24vw");

      setMainItemsPos("absolute");

      setMainImgLeft("-40px");
      setContentRight("0px");

      setContentGap("3.0vw");
      setContentTop("23%");

      setIamFS("3.0vw");
      setNameFS("7.0vw");
      setJobFS("4.5vw");
      setNameAfterHeight("0.5vw");
      setNameMarginBottom("1.1vw");
    }
    // tablet landscape
    else if (
      windowWidth < 1200 &&
      windowWidth >= 450 &&
      windowHeight < 850 &&
      windowHeight >= 450
    ) {
      setContainerWidth("92vw");
      setContainerHeight("57vw");

      setMainImgWidth("57vw");
      setMainImgHeight("97%");
      setMainContentWidth("50vw");
      setMainContentHeight("24vw");

      setMainItemsPos("absolute");

      setMainImgLeft("-10px");
      setContentRight("0px");

      setContentGap("3.0vw");
      setContentTop("23%");

      setIamFS("3.5vw");
      setNameFS("7.0vw");
      setJobFS("4.5vw");
      setNameAfterHeight("0.5vw");
      setNameMarginBottom("1.1vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setContainerWidth("100vw");
      setContainerHeight("57vw");

      setMainImgWidth("57vw");
      setMainImgHeight("97%");
      setMainContentWidth("58vw");
      setMainContentHeight("24vw");

      setMainItemsPos("absolute");
      setMainImgLeft("-20px");

      setContentRight("20px");
      setContentGap("3.0vw");
      setContentTop("20%");

      setIamFS("3.7vw");
      setNameFS("7.5vw");
      setJobFS("4.7vw");
      setNameAfterHeight("0.7vw");
      setNameMarginBottom("1.1vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setContainerWidth("1400px");
      setContainerHeight("800px");

      setMainImgWidth("800px");
      setMainImgHeight("97%");
      setMainContentWidth("800px");
      setMainContentHeight("340px");

      setMainItemsPos("absolute");
      setMainImgLeft("-20px");
      setContentRight("20px");
      setContentGap("3.0rem");
      setContentTop("20%");
      setIamFS("3.2rem");
      setNameFS("6.5rem");
      setJobFS("4.2rem");
      setNameAfterHeight("0.7rem");
      setNameMarginBottom("1.1rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContainerArea
      id="home" // for HashLink
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      mainContentWidth={mainContentWidth}
      mainContentHeight={mainContentHeight}
      mainImgHeight={mainImgHeight}
      mainImgLeft={mainImgLeft}
      contentRight={contentRight}
      contentTop={contentTop}
      contentGap={contentGap}
      IamFS={IamFS}
      nameFS={nameFS}
      jobFS={jobFS}
      mainImgWidth={mainImgWidth}
      mainItemsPos={mainItemsPos}
      nameAfterHeight={nameAfterHeight}
      nameMarginBottom={nameMarginBottom}
      style={{
        height: containerHeight,
        width: "100vw",
      }}
    >
      <div
        className="main"
        style={{
          width: containerWidth,
          flexDirection:
            windowHeight < 850 && windowWidth < 450 ? "column" : "row",
        }}
      >
        <Image
          className={"mainImg"}
          width={"100%"}
          // height={"100%"}
          image={mainImg}
          verticalAllign={"flex-start"}
          info={"onur karagüler main image"}
        />

        <div
          className="content"
          style={{ backgroundColor: windowWidth < 550 && mainColor }}
        >
          <div className="introduction">
            <h2 className="Iam">Hello, I'm</h2>
            <h1 className="name">
              Onur <span>Karagüler</span>
            </h1>
          </div>
          <div className="jobs">
            {jobs.map((item, index) => (
              <h2
                className={activeJobIndex === index ? "job active" : "job"}
                key={index}
              >
                {item}
              </h2>
            ))}
          </div>
        </div>
      </div>
    </ContainerArea>
  );
};

const ContainerArea = styled.div`
  background-color: ${(props) => props.mainColor};
  background-image: radial-gradient(
      at 57% 43%,
      hsl(208.24, 63%, 11%) 0,
      transparent 79%
    ),
    radial-gradient(at 22% 45%, hsl(208.24, 64%, 47%) 0, transparent 55%);

  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .main {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .mainImg {
      z-index: 1;
      width: ${(props) => props.mainImgWidth};
      height: ${(props) => props.mainImgHeight};
      left: ${(props) => props.mainImgLeft};
      bottom: 0;
      position: ${(props) => props.mainItemsPos};
    }

    .content {
      width: ${(props) => props.mainContentWidth};
      height: ${(props) => props.mainContentHeight};
      position: ${(props) => props.mainItemsPos};
      right: ${(props) => props.contentRight};
      top: ${(props) => props.contentTop};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${(props) => props.contentGap};

      .introduction {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .Iam {
          font-size: ${(props) => props.IamFS};
          color: ${(props) => props.mainColor2};
        }

        .name {
          font-size: ${(props) => props.nameFS};
          text-align: center;
          font-weight: 700;
          margin-bottom: ${(props) => props.nameMarginBottom};

          position: relative;
          color: ${(props) => props.mainColor2};

          &::after {
            content: "";
            position: absolute;
            height: ${(props) => props.nameAfterHeight};
            border-radius: 50%;
            left: 0;
            margin-bottom: calc(${(props) => props.nameMarginBottom} * -1);

            width: 0%;

            animation: loading 2s forwards;
            -webkit-animation: loading 2s forwards;
          }

          @keyframes loading {
            0% {
              width: 0%;
              background-color: ${(props) => props.mainColor2};
            }

            100% {
              width: 100%;
              background-color: ${(props) => props.mainColor2};
            }
          }
        }
      }

      .jobs {
        background-color: beige;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;

        .job {
          position: absolute;
          color: ${(props) => props.mainColor3};
          text-align: center;
          font-size: ${(props) => props.jobFS};
          font-weight: 500;
          transition: opacity 2s;

          visibility: hidden;
          opacity: 0;

          &.active {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
`;

export default Home;
