import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

const ScrollTopBtn = ({ toHeader }) => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const white = useSelector((state) => state.generalStore.white);

  //-----------------------\\
  //*-- Scroll position --*\\
  //-----------------------\\
  const [scrollPosition, setScrollPosition] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);

  useEffect(() => {
    if (scrollPosition >= 600) {
      setScrollTopBtn(true);
    } else {
      setScrollTopBtn(false);
    }
  }, [scrollPosition]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [scrollTopSize, setScrollTopSize] = useState(3);
  const [scrollTopBottom, setScrollTopBottom] = useState(8);
  const [scrollTopRight, setScrollTopRight] = useState(1);

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setScrollTopSize("9vw");
      setScrollTopBottom("2rem");
      setScrollTopRight("1.0rem");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setScrollTopSize("4.5vw");
      setScrollTopBottom("2rem");
      setScrollTopRight("2rem");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setScrollTopSize("5.0vw");
      setScrollTopBottom("2rem");
      setScrollTopRight("2rem");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setScrollTopSize("4.0vw");
      setScrollTopBottom("2rem");
      setScrollTopRight("2rem");
    }
    // computer
    else if (windowWidth < 1400) {
      setScrollTopSize("3.5vw");
      setScrollTopBottom("2rem");
      setScrollTopRight("2rem");
    }
    // computer
    else if (windowWidth >= 1400) {
      setScrollTopSize("3rem");
      setScrollTopBottom("2rem");
      setScrollTopRight("2rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    scrollTopBtn && (
      <ScrollTopBtnArea
        mainColor={mainColor}
        mainColor2={mainColor2}
        mainColor3={mainColor3}
        lightgray={lightgray}
        gray={gray}
        darkgray={darkgray}
        white={white}
      >
        <HashLink
          className="scrollTop"
          smooth
          to={toHeader}
          style={{
            width: scrollTopSize,
            height: scrollTopSize,
            bottom: scrollTopBottom,
            right: scrollTopRight,
          }}
        >
          <svg className="next-button-icon" viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              className="arrow"
              transform="translate(100, 100) rotate(180) "
            ></path>
          </svg>
        </HashLink>
      </ScrollTopBtnArea>
    )
  );
};

const ScrollTopBtnArea = styled.div`
  .scrollTop {
    z-index: 10;
    border-radius: 50%;
    cursor: pointer;

    position: fixed;
    right: 1vw;
    transform: rotate(-90deg);
    transition: all 0.3s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 6px;

      fill: ${(props) => props.gray};
      border: 2px solid ${(props) => props.gray};
      border-radius: 50%;
      user-select: none;
    }

    &:hover {
      background-color: ${(props) => props.mainColor3};

      svg {
        border: 0px solid ${(props) => props.gray};
        fill: ${(props) => props.white};
      }
    }
  }
`;

export default ScrollTopBtn;
