import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import CategorySlider from "../standard/CategorySlider";
import MainSliderBullets from "../standard/MainSliderBullets";

import Image from "../standard/Image";
import turquoise from "../../images/turquoise.png";
import web from "../../images/web.jpeg";
import data from "../../images/onur_exp_data.png";
import kayalar from "../../images/onur_exp_kayalar.png";
import vorne from "../../images/onur_exp_vorne.png";
import ilyatek from "../../images/onur_exp_ilyatek.png";
import samet from "../../images/onur_exp_samet.png";
import opsan from "../../images/onur_exp_opsan.png";
import turan from "../../images/onur_exp_turan.png";

const useExperiences = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  const mainSliderItems = {
    tuquoise: {
      no: 1,
      name: "Turquoise Choice",
      image: turquoise,
      dates: "04.2023 - ...",
      company: `
      Turquoise Choice is a dynamic startup company specializing in pickleball sports technology, a rapidly growing sport that combines elements of tennis, badminton, and ping pong. My role at Turquoise Choice involves the development of web applications specifically designed to meet the unique needs or requirements of the pickleball community.

      As a Front-end Developer, I create functional and visually appealing web applications, aligning UX/UI designs created using Figma with a mobile-first approach. The key skills that I bring to this role include proficiency in HTML, CSS, JavaScript, and occasional PHP coding for specific frontend requirements. I am skilled in implementing AJAX functionality by collaborating with Backend Developers, ensuring a smooth and continuous user experience.

      In this role, I collaborate effectively with the UX/UI Designer, the Product Manager, and Backend Developers, using Microsoft Teams to communicate technical needs and capabilities while developing and supporting key applications and features. We are using Git for version control with Azure Repositories, ensuring efficient code management and collaborative development.

      In addition to my core skills, I am actively engaged in expanding my knowledge and practical experience with emerging technologies, including Next.js 13, TypeScript, and Tailwindcss. I have initiated personal projects that allow me to gain hands-on experience and reinforce my skills. These projects not only serve as opportunities for continuous learning but also demonstrate my commitment to staying current in a rapidly evolving tech landscape.`,
      link: "https://turquoisechoice.com/",
      title: "Front-end Developer",
      experience: [],
    },
    web: {
      no: 1,
      name: "React Developer",
      image: web,
      dates: "01.2022 - 04.2023",
      company: `In January 2022, I made an intentional career switch to web development. This decision was inspired by an exciting  opportunity presented by my former colleague to contribute to their company's website development. I started by enrolling in online courses to learn the fundamentals of web development, including HTML, CSS (utilizing Sass for styling), and JavaScript.

      Recognizing the surging popularity of React, I dedicated myself to mastering it, as well as cultivating proficiency in other vital technologies such as Redux for state management, React Router DOM for creating dynamic user interfaces, and Styled Components for enhancing the maintainability of web applications' styling. Alongside this, I gained practical experience with Node.js and its capabilities for developing high-performance applications.

      This continuous journey of learning and exploration has played a crucial role in enhancing my skills and capabilities. Through dedication and perseverance, I've improved my proficiency in web development technologies through dedicated effort and practice, enabling me to create dynamic, highly responsive, and user-friendly web applications.`,
      link: false,
      title: "React Developer",
      experience: [],
    },
    data: {
      no: 2,
      name: "Data Scientist",
      image: data,
      dates: "11.2019 - 01.2022",
      company: "",
      link: false,
      title: "Data Scientist",
      experience: [
        "Have excellent programming skill in Python",
        "Proficient in Object Oriented Programming",
        "Hands on experience in data preprocessing, feature selection using Pandas, Numpy and Scikit-learn libraries",
        "Have good knowledge of Statistics and Machine Learning techniques (regression, classification …)",
        "Well experience in data analysis with Machine Learning libraries (Scikit-learn, XGBoost …)",
        "Have knowledge on deep learning libraries (TensorFlow, Keras)",
        "Data visualization with Matplotlib and Plotly",
        "Write and execute various MYSQL database queries from python using Python-MySQL connector",
        "Expertise in web scraping using Selenium, Requests and Beautiful Soup",
        "Experience with big data tools: PySpark",
        "Hands on experience in desktop applications for windows, linux and Mac using PyQt5 package",
      ],
    },
    kayalar: {
      no: 3,
      name: "Kayalar Endüstriyel Mutfak A.Ş.",
      image: kayalar,
      dates: "12.2018-08.2019",
      company:
        "Km Kayalar Industrial Kitchen is an industrial kitchen manufacturer. It is one of the well-known companies in its sector in Turkey.",
      link: "https://www.kayalarmutfak.com/",
      title: "Research and Development Manager",
      experience: [
        "Management of R&D team in line with project plans",
        "Lead all projects involving benchmarking, product designing, prototyping, testing processes",
        "New product development and quality/cost reduction studies on existing products",
      ],
    },
    vorne: {
      no: 4,
      name: "İleri Pencere Kapı Sistemleri A.Ş.",
      image: vorne,
      dates: "02.2017-06.2018",
      company:
        "VORNE is a leading company in the window and door systems in Turkey. Vorne was founded in 1985, producing their products in a facility with an open area of 50000 m2 and closed area of 36000 m2.",
      link: "https://www.vorne.com.tr/",
      title: "Research and Development Team Leader",
      experience: [
        "Established and directed R&D Center and management of R&D team in line with project plans",
        "Lead all projects involving benchmarking, product designing, patent, prototyping, molding, testing, trial and serial production processes",
        "New product development and quality/cost reduction studies on existing products",
        "Coordination of technical studies with Quality, Production, Planning, Purchasing and Sales Departments",
      ],
    },
    ilyatek: {
      no: 5,
      name: "İlyatek Metal İşleme",
      image: ilyatek,
      dates: "09.2015-01.2017",
      company:
        "Ilyatek, which I set up with a Modig brand high speed CNC Vertical Machine, was a small machine shop offering 3-axis CNC machining services. Modig MD-7200 Precision CNC Vertical Machining Center: Table Size: 900 x 350 mm Travel (X, Y, Z): 750 x 400 x 500 mm Rapid Movement Speeds (X / Y / Z): 24 / 24 / 20 m / min Magazine Number: 24 Pcs. Spindle: HSK 63A taper Spindle Speed: 24,000 Rpm Control Unit: Heidenhain TNC 426 M",
      link: false,
      title: "Business Owner",
      experience: [
        "Machining of parts by using 3D files and technical drawings supplied by clients",
      ],
    },
    samet: {
      no: 6,
      name: "Samet Kalıp A.Ş.",
      image: samet,
      dates: "05.2004-04.2015",
      company:
        "SAMET A.Ş. is a leading company in the furniture accessories industry in Turkey. It has more than 1,150 employees. Samet carries out its production activities in a total area of 80.500 m², 32.000 m² of which is closed.",
      link: "https://www.samet.com.tr/",
      title: "Product Design Group Leader",
      experience: [
        "Marketing - Product Manager (1,5 year)",
        "R&D - Product Design Group Leader (4 years)",
        "R&D - Product Designer (4,5 years)",
        "R&D - CAD/CAM Specialist (1 year)",
      ],
    },
    opsan: {
      no: 7,
      name: "Opsan A.Ş.",
      image: opsan,
      dates: "04.2003-02.2004",
      company:
        "Opsan is an automotive supplier industry company and is a leader in its field. Opsan produces original sheet metal parts and welded assemblies for the automotive industry.",
      link: "https://opsan.com.tr/en/index.html",
      title: "CAD/CAM Specialist",
      experience: [
        "Controled 3D data and technical drawings supplied by designers and prepared CAM programs using the Cimatron software program",
        "Determined to cut progress and speeds of various cutting tools",
        "Designed apparatus and fixture",
      ],
    },
    turan: {
      no: 8,
      name: "Turan Ambalaj Ltd. Şti.",
      image: turan,
      dates: "03.2002-01.2003",
      company:
        "Turan Ambalaj produces plastic ayran, yoghurt, juice, margarine and detergent containers and lids using the thermoforming technologies. Raw materials are ABS, PS, PP and PVC.",
      title: "Production Engineer",
      link: "https://turanambalaj.com/en/",
      experience: [
        "Was responsible for Extrusion, Thermoform, and Dry Offset sections. In the Extrusion the thermoplastic sheet is produced. In the Thermoform plastic ayran, yoghurt, juice, margarine and detergent containers and lids are produced. In the Dry Offset Dry offset printing is applied to cups and lids after molding.",
        "Planned, scheduled and monitored the production processes according to the daily list given by the planning department",
        "Coordinated machinery, personnel and materials to meet production requirements",
        "Controlled the quality of products on each machine, the packaging and shipping of the final products",
      ],
    },
  };

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [cardWidth, setCardWidth] = useState(600);
  const [cardHeight, setCardHeight] = useState(350);
  const [widthType, setWidthType] = useState("px");
  const [gridColumns, setGridColumns] = useState(1);

  const [bulletWidth, setBulletWidth] = useState(4);
  const [bulletHeight, setBulletHeight] = useState(2);
  const [bulletGap, setBulletGap] = useState(1.5);
  const [nextPrevSize, setNextPrevSize] = useState("3vw");
  const [nextPrevLRDist, setNextPrevLRDist] = useState("1rem");
  const [nextPrevBorderThickness, setNextPrevBorderThickness] =
    useState("0.1vw");

  const [contentWidth, setContentWidth] = useState();
  const [contentPaddingLeft, setContentPaddingLeft] = useState("2rem");
  const [jobTitleFS, setJobTitleFS] = useState("2rem");
  const [companyInfoFS, setCompanyInfoFS] = useState("1.4rem");

  const [jobHeight, setJobHeight] = useState("2.7rem");
  const [jobBottom, setJobBottom] = useState("3rem");
  const [jobFS, setJobFS] = useState("1.7rem");

  const [dateFS, setDateFS] = useState("1.4rem");
  const [dateRight, setDateRight] = useState("-20rem");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setCardWidth(96);
      setWidthType("vw");
      setCardHeight(80);
      setGridColumns(1);

      setBulletWidth(7.3);
      setBulletHeight(7.3);
      setBulletGap(3.0);
      setNextPrevSize("8vw");
      setNextPrevLRDist("1vw");
      setNextPrevBorderThickness("0");

      setContentWidth("98vw");
      setContentPaddingLeft("0rem");
      setJobTitleFS("1.5rem");
      setCompanyInfoFS("1.1rem");

      setJobHeight("8.0vw");
      setJobBottom("9.0vw");
      setJobFS("5.0vw");
      setDateFS("4.0vw");
      setDateRight("-50vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setCardWidth(40);
      setWidthType("vw");
      setCardHeight(35);
      setGridColumns(1);

      setBulletWidth(3.0);
      setBulletHeight(2);
      setBulletGap(1.0);
      setNextPrevSize("3.5vw");
      setNextPrevLRDist(".5vw");
      setNextPrevBorderThickness("0");

      setContentWidth("52vw");
      setContentPaddingLeft("1rem");
      setJobTitleFS("2.0vw");
      setCompanyInfoFS("1.5vw");

      setJobHeight("3.0vw");
      setJobBottom("4.0vw");
      setJobFS("2.0vw");
      setDateFS("1.7vw");
      setDateRight("-20vw");
    }
    // tablet portrait
    else if (
      windowWidth < 850
      // windowWidth < 850 &&
      // windowWidth >= 550 &&
      // windowHeight < 1200 &&
      // windowHeight >= 550
    ) {
      setCardWidth(40);
      setWidthType("vw");
      setCardHeight(35);
      setGridColumns(1);

      setBulletWidth(3.0);
      setBulletHeight(2);
      setBulletGap(1.0);
      setNextPrevSize("3.2vw");
      setNextPrevLRDist(".5vw");
      setNextPrevBorderThickness("0");

      setContentWidth("52vw");
      setContentPaddingLeft("1rem");
      setJobTitleFS("2.0vw");
      setCompanyInfoFS("1.5vw");

      setJobHeight("2.8vw");
      setJobBottom("3.3vw");
      setJobFS("2.0vw");
      setDateFS("1.7vw");
      setDateRight("-20vw");
    }
    // tablet landscape
    else if (
      windowWidth < 1200
      // windowWidth < 1200 &&
      // windowWidth >= 550 &&
      // windowHeight < 850 &&
      // windowHeight >= 550
    ) {
      setCardWidth(40);
      setWidthType("vw");
      setCardHeight(32);
      setGridColumns(1);

      setBulletWidth(3.3);
      setBulletHeight(2);
      setBulletGap(1.0);
      setNextPrevSize("3.0vw");
      setNextPrevLRDist(".5vw");
      setNextPrevBorderThickness("0");

      setContentWidth("52vw");
      setContentPaddingLeft("1rem");
      setJobTitleFS("2.0vw");
      setCompanyInfoFS("1.5vw");

      setJobHeight("2.8vw");
      setJobBottom("3.3vw");
      setJobFS("1.8vw");
      setDateFS("1.5vw");
      setDateRight("-20vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setCardWidth(40);
      setWidthType("vw");
      setCardHeight(30);
      setGridColumns(1);

      setBulletWidth(3.3);
      setBulletHeight(2);
      setBulletGap(1.0);
      setNextPrevSize("3vw");
      setNextPrevLRDist("1vw");
      setNextPrevBorderThickness("0.1vw");

      setContentWidth("50vw");
      setContentPaddingLeft("2rem");
      setJobTitleFS("2.5vw");
      setCompanyInfoFS("1.6vw");

      setJobHeight("2.7vw");
      setJobBottom("3.0vw");
      setJobFS("1.7vw");
      setDateFS("1.4vw");
      setDateRight("-20vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setCardWidth(600);
      setCardHeight(450);
      setWidthType("px");
      setGridColumns(1);

      setBulletWidth(55);
      setBulletHeight(35);
      setBulletGap(10);
      setNextPrevSize("40px");
      setNextPrevLRDist("1rem");
      setNextPrevBorderThickness("2.0px");

      setContentWidth("800px");
      setContentPaddingLeft("2rem");
      setJobTitleFS("2rem");
      setCompanyInfoFS("1.4rem");

      setJobHeight("2.7rem");
      setJobBottom("3.0rem");
      setJobFS("1.7rem");
      setDateFS("1.4rem");
      setDateRight("-20rem");
    }
  }, [windowWidth, windowHeight]);

  //----------------------\\
  //*- Infinite Sliding -*\\
  //----------------------\\
  const [slides, setSlides] = useState(false);

  useEffect(() => {
    Object.values(slides).map((slide, index) => {
      // console.log(slide);
    });
  }, [slides]);

  useEffect(() => {
    const firstKey = Object.keys(mainSliderItems)[0]; // get the first key
    const firstValue = mainSliderItems[firstKey]; // get the first value
    let newMainSlider = {
      ...mainSliderItems,
      [firstKey + "_copy"]: firstValue,
    }; // create a new dictionary with the cloned item at the end
    newMainSlider = JSON.parse(JSON.stringify(newMainSlider)); // deep clone the dictionary
    newMainSlider[
      Object.keys(newMainSlider)[Object.keys(newMainSlider).length - 1]
    ].no = Object.keys(newMainSlider).length;

    const lastKey =
      Object.keys(mainSliderItems)[Object.keys(mainSliderItems).length - 1]; // get the last key
    const lastValue = mainSliderItems[lastKey]; // get the last value
    lastValue.no = Object.keys(newMainSlider).length;
    newMainSlider = { [lastKey + "_copy"]: lastValue, ...newMainSlider }; // create a new dictionary with the cloned item at the beginning
    newMainSlider = JSON.parse(JSON.stringify(newMainSlider)); // deep clone the dictionary
    newMainSlider[Object.keys(newMainSlider)[0]].no = 0;

    setSlides(newMainSlider);
  }, [windowWidth]);

  //-----------------\\
  //*--- Bullets ---*\\
  //-----------------\\
  const [activeSlideNo, setActiveSlideNo] = useState(false);
  const [activeSlide, setActiveSlide] = useState(false);
  const [activeSlideNoFromBullets, setActiveSlideNoFromBullets] =
    useState(false);
  const [showHideNextBtn, setShowHideNextBtn] = useState(true);
  const [showHidePrevBtn, setShowHidePrevBtn] = useState(true);
  const clickOnBullet = (index) => {
    setActiveSlideNoFromBullets(index);
  };

  useEffect(() => {
    if (
      activeSlideNo !== false &&
      activeSlideNo >= 0 &&
      activeSlideNo < Object.keys(mainSliderItems).length
    ) {
      setActiveSlide(
        mainSliderItems[Object.keys(mainSliderItems)[activeSlideNo]]
      );
    }
  }, [activeSlideNo]);

  if (slides) {
    const mainSliderDivs = Object.values(slides).map((slide, index) => (
      <div className="slideItem" key={index.toString()}>
        <div className="image">
          <a href={slide.link} target="_blank">
            <Image
              className={"image"}
              // width={"100%"}
              height={"100%"}
              image={slide.image}
              info={slide.name}
            />
          </a>
        </div>
      </div>
    ));

    const jobDivs = Object.values(mainSliderItems).map((slide, index) => (
      <div
        className={activeSlideNo === index ? "job active" : "job"}
        key={index.toString()}
      >
        <h1>{slide.name}</h1>
      </div>
    ));

    const dateDivs = Object.values(mainSliderItems).map((slide, index) => (
      <div
        className={activeSlideNo === index ? "date active" : "date"}
        key={index.toString()}
      >
        <h1>{slide.dates}</h1>
      </div>
    ));

    return (
      <ContainerArea
        // id="experiencesSliderArea" // for HashLink
        mainColor={mainColor}
        mainColor2={mainColor2}
        mainColor3={mainColor3}
        mainColor4={mainColor4}
        lightgray={lightgray}
        gray={gray}
        darkgray={darkgray}
        white={white}
        contentWidth={contentWidth}
        contentPaddingLeft={contentPaddingLeft}
        jobTitleFS={jobTitleFS}
        companyInfoFS={companyInfoFS}
        jobHeight={jobHeight}
        jobBottom={jobBottom}
        jobFS={jobFS}
        dateFS={dateFS}
        dateRight={dateRight}
        style={{
          gap: windowHeight < 850 && windowWidth < 450 ? "1rem" : false,
        }}
      >
        <div className="sliderArea">
          <CategorySlider
            className="mainSlider"
            carousel={true}
            imagesList={mainSliderDivs}
            jobDivs={jobDivs}
            dateDivs={dateDivs}
            cardWidth={cardWidth}
            scrollableContentWidth={cardWidth * gridColumns}
            widthType={widthType}
            scrollableContentHeight={cardHeight}
            autoSlide={false}
            intervalTime={10000}
            getActiveSlideNo={setActiveSlideNo}
            showHideNextBtn={showHideNextBtn}
            showHidePrevBtn={showHidePrevBtn}
            activeSlideNoFromBullets={activeSlideNoFromBullets}
            size={nextPrevSize}
            borderThickness={nextPrevBorderThickness}
            nextPrevLRDist={nextPrevLRDist}
            showNextPrevArrow={true}
          />
          <MainSliderBullets
            imagesList={Object.values(mainSliderItems)}
            onClick={clickOnBullet}
            activeSlideNo={activeSlideNo}
            showHideNextBtn={setShowHideNextBtn}
            showHidePrevBtn={setShowHidePrevBtn}
            widthType={widthType}
            bulletWidth={bulletWidth}
            bulletHeight={bulletHeight}
            bulletGap={bulletGap}
            borderRadius={".3rem"}
            autoSlide={false}
            // specify the intervalTime inside the component as 5s
          />
        </div>

        <div className="content">
          {activeSlide !== false && (
            <h1 className="jobTitle">{activeSlide.title}</h1>
          )}
          {activeSlide !== false && activeSlide.company !== "" && (
            <p className="companyInfo">{activeSlide.company}</p>
          )}
          <ul className="expItems" style={{ marginLeft: "1rem" }}>
            {/* <br></br> */}
            {/* <br></br> */}
            {activeSlide !== false &&
              activeSlide.experience.map((item, index) => (
                <li
                  className="expItem"
                  key={index}
                  style={{ listStyle: "square outside none" }}
                >
                  {item}
                </li>
              ))}
          </ul>

          {activeSlide !== false && activeSlideNo === 4 && (
            <a
              href="https://www.youtube.com/watch?v=J4F7FYH4P_Q"
              target="_blank"
            >
              Please watch one of my work
            </a>
          )}
        </div>
      </ContainerArea>
    );
  }
};

const ContainerArea = styled.div`
  /* background-color: ${(props) => props.mainColor3}; */
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  .sliderArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    .mainSlider {
      .job {
        z-index: 1;
        background-color: ${(props) => props.mainColor3};
        position: absolute;
        width: 100%;
        /* height: 2.7vw; */
        height: ${(props) => props.jobHeight};
        bottom: calc(${(props) => props.jobBottom} * -1);

        animation-name: slide_upwards;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-fill-mode: ease-in;
        -webkit-animation-name: slide_upwards;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-fill-mode: ease-in;

        display: none;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: ${(props) => props.jobFS};
          color: ${(props) => props.mainColor2};
        }

        &.active {
          display: flex;
        }

        @keyframes slide_upwards {
          100% {
            bottom: 0;
          }
        }
      }

      .date {
        z-index: 1;
        background-color: ${(props) => props.mainColor3};
        position: absolute;
        padding: 0.3vw 1vw;
        border-radius: 0.3rem;

        top: 1rem;
        right: ${(props) => props.dateRight};

        animation-name: slide_left;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-fill-mode: ease-in;
        -webkit-animation-name: slide_left;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-fill-mode: ease-in;

        display: none;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: ${(props) => props.dateFS};
          color: ${(props) => props.mainColor2};
        }

        &.active {
          display: flex;
        }

        @keyframes slide_left {
          100% {
            right: 1rem;
          }
        }
      }

      .slides {
        .slide {
          .slideItem {
            width: 100%;
            height: 100%;
            .image {
              width: 100%;
              height: 100%;
              img {
                height: 102%;
              }
            }
          }
        }
      }
    }
  }

  .content {
    width: ${(props) => props.contentWidth};
    min-width: 230px;
    height: 100%;
    padding-left: ${(props) => props.contentPaddingLeft};

    .jobTitle {
      color: ${(props) => props.mainColor3};
      font-size: ${(props) => props.jobTitleFS};
      padding-bottom: 0.5rem;
    }

    .companyInfo {
      color: ${(props) => props.mainColor2};
      font-size: ${(props) => props.companyInfoFS};
      padding-bottom: 0.5rem;
    }

    .expItems {
      .expItem {
        font-size: ${(props) => props.companyInfoFS};
        color: ${(props) => props.mainColor2};
      }
    }

    a {
      color: ${(props) => props.mainColor3};
      font-size: ${(props) => props.companyInfoFS};
    }
  }
`;

export default useExperiences;
