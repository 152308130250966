import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  EnvelopeIcon,
  GithubIcon,
  KaggleIcon,
  LinkedinIcon,
  YoutubeIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  GooglemapsIcon,
} from "../standard/Icons";

const ContactInfo = () => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  const windowHeight = useSelector(
    (state) => state.generalStore.browserSize
  ).height;

  const [contactFormWidth, setContactFormWidth] = useState("41vw");
  const [infoWidth, setInfoWidth] = useState("34vw");
  const [infoBoxesGap, setInfoBoxesGap] = useState(false);

  const [infoiconLinkSize, setInfoiconLinkSize] = useState("2.8vw");
  const [infoIconSize, setInfoIconSize] = useState("2.8vw");
  const [boxInfoWidth, setBoxInfoWidth] = useState("30vw");
  const [boxInfoFS, setBoxInfoFS] = useState("1.4vw");

  const [headerHeight, setHeaderHeight] = useState("13rem");
  const [imageWidth, setImageWidth] = useState("40%");
  const [boxWidth, setBoxWidth] = useState(false);
  const [socialIconLinkSize, setSocialIconLinkSize] = useState("2.4vw");

  useEffect(() => {
    // mobile portrait
    if (windowWidth < 550) {
      setContactFormWidth("98%");
      setInfoWidth("100%");
      setInfoBoxesGap("1.0vw");
      setHeaderHeight("20vw");
      setImageWidth("100%");
      setBoxWidth("96%");
      setBoxInfoWidth("80vw");
      setInfoiconLinkSize("8vw");
      setInfoIconSize("4vw");
      setBoxInfoFS("3.5vw");
      setSocialIconLinkSize("5.0vw");
    }
    // mobile landscape
    else if (windowWidth < 850 && windowHeight < 550) {
      setInfoWidth("45vw");
      setInfoBoxesGap("0.6vw");
      setContactFormWidth("45vw");
      setHeaderHeight("13vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setBoxInfoWidth("40vw");
      setInfoiconLinkSize("3.5vw");
      setInfoIconSize("1.8vw");
      setBoxInfoFS("1.7vw");
      setSocialIconLinkSize("2.5vw");
    }
    // tablet portrait
    else if (windowWidth < 850) {
      setContactFormWidth("45vw");
      setInfoWidth("45vw");
      setInfoBoxesGap("0.6vw");
      setHeaderHeight("13vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setBoxInfoWidth("39vw");
      setInfoiconLinkSize("4vw");
      setInfoIconSize("2vw");
      setBoxInfoFS("1.7vw");
      setSocialIconLinkSize("2.8vw");
    }
    // tablet landscape
    else if (windowWidth < 1200) {
      setContactFormWidth("45vw");
      setInfoWidth("45vw");
      setInfoBoxesGap("0.6vw");
      setHeaderHeight("13vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setBoxInfoWidth("40vw");
      setInfoiconLinkSize("3.5vw");
      setInfoIconSize("1.8vw");
      setBoxInfoFS("1.8vw");
      setSocialIconLinkSize("2.6vw");
    }
    // computer
    else if (windowWidth < 1400) {
      setContactFormWidth("45vw");
      setInfoWidth("45vw");
      setInfoBoxesGap("0.6vw");
      setHeaderHeight("15vw");
      setImageWidth("40%");
      setBoxWidth("100%");
      setBoxInfoWidth("40vw");
      setInfoiconLinkSize("3.2vw");
      setInfoIconSize("1.7vw");
      setBoxInfoFS("1.7vw");
      setSocialIconLinkSize("2.6vw");
    }
    // computer
    else if (windowWidth >= 1400) {
      setContactFormWidth("43rem");
      setInfoWidth("43rem");
      setInfoBoxesGap("0.6rem");
      setHeaderHeight("13rem");
      setImageWidth("40%");
      setBoxWidth("100%");
      setBoxInfoWidth("38rem");
      setInfoiconLinkSize("3.2rem");
      setInfoIconSize("1.7rem");
      setBoxInfoFS("1.7rem");
      setSocialIconLinkSize("2.3rem");
    }
  }, [windowWidth, windowHeight]);

  return (
    <ContactInfoArea
      className="infoContainer"
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      contactFormWidth={contactFormWidth}
      infoWidth={infoWidth}
      infoBoxesGap={infoBoxesGap}
      infoiconLinkSize={infoiconLinkSize}
      infoIconSize={infoIconSize}
      boxInfoWidth={boxInfoWidth}
      boxInfoFS={boxInfoFS}
      headerHeight={headerHeight}
      imageWidth={imageWidth}
      boxWidth={boxWidth}
      socialIconLinkSize={socialIconLinkSize}
    >
      <div className="titleElement">
        <h3 className="title">Contact Info</h3>
      </div>

      <div className="info">
        <div className="infoBoxes">
          <div className="box">
            <a
              href="https://www.google.com/maps/place/Adnan+Kahveci,+34528+Beylikd%C3%BCz%C3%BC%2F%C4%B0stanbul/@41.00109,28.627947,13z/data=!4m5!3m4!1s0x14b55fb4980ae2cf:0xfc9959c2a4cfa69e!8m2!3d40.9988818!4d28.6293961?hl=tr&gl=TR"
              target="_blank"
              rel="noreferrer"
              className="iconArea"
            >
              <GooglemapsIcon
                width={infoIconSize}
                height={infoIconSize}
                color={mainColor2}
              />
            </a>
            <p className="boxInfo">
              Adnan Kahveci Mah. Beylikdüzü, İstanbul / Türkiye
            </p>
          </div>

          <div className="box">
            <a href="mailto:onurkaraguler@hotmail.com" className="iconArea">
              <EnvelopeIcon
                width={infoIconSize}
                height={infoIconSize}
                color={mainColor2}
              />
            </a>
            <p className="boxInfo">onurkaraguler@hotmail.com</p>
          </div>

          <div className="box">
            <a
              href="https://www.linkedin.com/in/onurkaraguler/"
              target="_blank"
              rel="noreferrer"
              className="iconArea"
            >
              <LinkedinIcon
                width={infoIconSize}
                height={infoIconSize}
                color={mainColor2}
              />
            </a>
            <p className="boxInfo">LinkedIn: onurkaraguler</p>
          </div>

          <div className="box">
            <a
              href="https://github.com/OnurKaraguler?tab=repositories"
              target="_blank"
              rel="noreferrer"
              className="iconArea"
            >
              <GithubIcon
                width={infoIconSize}
                height={infoIconSize}
                color={mainColor2}
              />
            </a>
            <p className="boxInfo">GitHub: OnurKaraguler</p>
          </div>

          <div className="box">
            <a
              href="https://www.kaggle.com/onurkaraguler"
              target="_blank"
              rel="noreferrer"
              className="iconArea"
            >
              <KaggleIcon
                width={infoIconSize}
                height={infoIconSize}
                color={mainColor2}
              />
            </a>
            <p className="boxInfo">Kaggle: onurkaraguler</p>
          </div>

          <div className="box social">
            <div className="header">Social Profiles:</div>

            <div className="icons">
              <a
                href="https://www.youtube.com/channel/UCFjIQycch3JwHFZi_57idVw"
                target="_blank"
                rel="noreferrer"
                className="iconArea"
              >
                <YoutubeIcon
                  width={infoIconSize}
                  height={infoIconSize}
                  color={mainColor2}
                />
              </a>
              <a
                href="https://twitter.com/karaguler_onur"
                target="_blank"
                rel="noreferrer"
                className="iconArea"
              >
                <TwitterIcon
                  width={infoIconSize}
                  height={infoIconSize}
                  color={mainColor2}
                />
              </a>

              <a
                href="https://www.facebook.com/onur.karaguler.50/"
                target="_blank"
                rel="noreferrer"
                className="iconArea"
              >
                <FacebookIcon
                  width={infoIconSize}
                  height={infoIconSize}
                  color={mainColor2}
                />
              </a>

              <a
                href="https://www.instagram.com/onur_karaguler/"
                target="_blank"
                rel="noreferrer"
                className="iconArea"
              >
                <InstagramIcon
                  width={infoIconSize}
                  height={infoIconSize}
                  color={mainColor2}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContactInfoArea>
  );
};

const ContactInfoArea = styled.div`
  width: ${(props) => props.contactFormWidth};
  display: flex;
  flex-direction: column;

  .info {
    width: ${(props) => props.infoWidth};

    .infoBoxes {
      display: flex;
      flex-direction: column;
      justify-items: center;
      gap: ${(props) => props.infoBoxesGap};

      .box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .iconArea {
          height: ${(props) => props.infoiconLinkSize};
          width: ${(props) => props.infoiconLinkSize};
          color: ${(props) => props.mainColor2};
          border-radius: 50%;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        .boxInfo {
          width: ${(props) => props.boxInfoWidth};
          font-size: ${(props) => props.boxInfoFS};
          color: ${(props) => props.mainColor2};
          margin-left: 0.4rem;
        }
        &.social {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 0.1rem;

          .header {
            font-size: ${(props) => props.boxInfoFS};
            color: ${(props) => props.mainColor2};
            height: calc(
              ${(props) => props.boxInfoFS} + ${(props) => props.boxInfoFS}
            );

            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .icons {
            display: flex;
            align-items: center;
            gap: 1rem;

            .iconArea {
              border: 0.1vw solid ${(props) => props.mainColor2};
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
`;

export default ContactInfo;
