import React from "react";
import { useState, useRef } from "react";
import styled from "styled-components";
import { FaPlay } from "react-icons/fa";

const Video = ({
  width,
  height,
  video,
  info,
  autoPlay,
  loop,
  muted,
  controls,
  playIconFontSize,
  playVideo,
}) => {
  const playIconRef = useRef();
  const [playIconState, setPlayIconState] = useState(true);
  const handleOnClick = () => {
    if (playVideo) {
      playIconRef.current.play();
    }
  };
  const handleOnPlay = () => {
    setPlayIconState(false);
  };
  const handleOnPause = () => {
    setPlayIconState(true);
  };
  const handleOnDrag = () => {
    setPlayIconState(false);
  };

  return (
    <VideoStyle
      className="video"
      width={width}
      height={height}
      style={{
        backgroundColor: "darkgray",
        userSelect: "none",
      }}
    >
      <video
        ref={playIconRef}
        src={video}
        onPlay={handleOnPlay}
        onPause={handleOnPause}
        onTimeUpdate={handleOnDrag}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        controls={controls}
        style={{
          width: "100%",
          // height: "100%",
          objectFit: "cover", // contain
        }}
        alt={info}
      />
      {playIconState && (
        <FaPlay
          className="playIcon"
          style={{
            fontSize: playIconFontSize,
          }}
          onClick={handleOnClick}
        />
      )}
    </VideoStyle>
  );
};

const VideoStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  .playIcon {
    z-index: 1;
    position: absolute;
    font-weight: normal;
    color: lightgray;
  }
`;

export default Video;
