import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { setSliderSlidingState } from "../stores/generalSlice";
import NextPrevArrow from "./NextPrevArrow";

const CategorySlider = ({
  className,
  carousel,
  imagesList,
  jobDivs,
  dateDivs,
  cardWidth,
  widthType,
  scrollableContentWidth,
  scrollableContentHeight,
  autoSlide,
  intervalTime,
  cursor,
  size,
  borderThickness,
  nextPrevLRDist,
  showNextPrevArrow,

  getActiveSlideNo,
  activeSlideNoFromBullets,
  setPopOverSliderState,
  // trigger the slider to move using the arrows outside of the slider component.
  getPrevBtnState,
  getNextBtnState,
  switchSlideTrigger,
  setSwitchSlideTrigger,
}) => {
  const mainColor = useSelector((state) => state.generalStore.mainColor);
  const mainColor2 = useSelector((state) => state.generalStore.mainColor2);
  const mainColor3 = useSelector((state) => state.generalStore.mainColor3);
  const mainColor4 = useSelector((state) => state.generalStore.mainColor4);
  const lightgray = useSelector((state) => state.generalStore.lightgray);
  const gray = useSelector((state) => state.generalStore.gray);
  const darkgray = useSelector((state) => state.generalStore.darkgray);
  const white = useSelector((state) => state.generalStore.white);

  const dispatch = useDispatch();

  const [activeSlideNo, setActiveSlideNo] = useState(0);
  const [slidesShown, setSlidesShown] = useState(0);
  const slidesOnScreen = scrollableContentWidth / cardWidth;

  const [showHidePrevBtn, setShowHidePrevBtn] = useState(true);
  const [showHideNextBtn, setShowHideNextBtn] = useState(true);
  useEffect(() => {
    if (getPrevBtnState) {
      getPrevBtnState(showHidePrevBtn);
    }

    if (getNextBtnState) {
      getNextBtnState(showHideNextBtn);
    }
  }, [getPrevBtnState, getNextBtnState, showHidePrevBtn, showHideNextBtn]);

  const [slideLeft, setSlideLeft] = useState();

  const [smoothSliding, setSmoothSliding] = useState(false);
  // for ignore fast click
  const [clickAllowance, setClickAllowance] = useState(true);
  // for touch sliding
  const [touchAction, setTouchAction] = useState(true);

  //--------------------\\
  //*--- Responsive ---*\\
  //--------------------\\
  const windowWidth = useSelector(
    (state) => state.generalStore.browserSize
  ).width;
  // const windowHeight = useSelector(
  //   (state) => state.generalStore.browserSize
  // ).height;

  let containerWidth =
    (scrollableContentWidth * document.documentElement.clientWidth) / 100;
  if (widthType === "px") {
    containerWidth = scrollableContentWidth;
  }
  //----------------------\\
  //*- For Card Sliding -*\\
  //----------------------\\
  useEffect(() => {
    if (!carousel && slidesOnScreen >= imagesList.length) {
      setShowHideNextBtn(false);
    } else {
      setShowHideNextBtn(true);
    }
  }, [imagesList, slidesOnScreen]);

  useEffect(() => {
    if (!carousel) {
      if (activeSlideNo === -1) {
        setActiveSlideNo(0);
      }
    }
  }, [activeSlideNo]);

  useEffect(() => {
    setSlidesShown(activeSlideNo * slidesOnScreen);

    if (!carousel) {
      if (activeSlideNo === 0) {
        setShowHidePrevBtn(false);
      } else {
        setShowHidePrevBtn(true);
      }
    }
  }, [activeSlideNo, slidesOnScreen]);

  useEffect(() => {
    if (!carousel && slidesShown >= imagesList.length) {
      setActiveSlideNo(0);
    }
  }, [slidesShown, imagesList.length]);

  //-----------------------\\
  //*----- For Bullets -----\\
  //-----------------------\\
  useEffect(() => {
    if (getActiveSlideNo) {
      getActiveSlideNo(activeSlideNo);
    }

    // this is for auto-sliding problem. Otherwise, activeSlideNo increases more then total slides
    if (activeSlideNo >= imagesList.length) {
      autoSliderFunc.stop();
      setActiveSlideNo(0);
      autoSliderFunc.start();
    }
  }, [activeSlideNo]);

  // from bullets
  useEffect(() => {
    if (activeSlideNoFromBullets || activeSlideNoFromBullets === 0) {
      // stop auto sliding during slide manually
      autoSliderFunc.stop();

      setSmoothSliding(true);
      setActiveSlideNo(activeSlideNoFromBullets);
      // start auto sliding again
      autoSliderFunc.start();
    }
  }, [activeSlideNoFromBullets]);

  //--------------------\\
  //*--- Auto Slide ---*\\
  //--------------------\\
  const startStopSlider = () => {
    function auto_next_slide() {
      setSmoothSliding(true);
      setActiveSlideNo((activeSlideNo) => activeSlideNo + 1);
    }
    let interval_setting;
    return {
      start() {
        if (autoSlide) {
          interval_setting = setInterval(auto_next_slide, intervalTime);
        }
      },
      stop() {
        clearInterval(interval_setting);
      },
    };
  };
  // start auto sliding if the slider is infinitive
  const [autoSliderFunc, setAutoSliderFunc] = useState(startStopSlider());
  useEffect(() => {
    autoSliderFunc.start();
  }, [autoSlide]);

  // when the webpace is visible
  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        autoSliderFunc.start();
      } else {
        autoSliderFunc.stop();
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  //----------------------\\
  //*--- Slide Action ---*\\
  //----------------------\\
  // if the window size changed
  useEffect(() => {
    if (carousel) {
      setSlideLeft(
        // convert from vw to px
        containerWidth * (activeSlideNo + 1) * -1
      );
    } else {
      setSlideLeft(
        // convert from vw to px
        containerWidth * activeSlideNo * -1
      );
    }
  }, [activeSlideNo, scrollableContentWidth, windowWidth]);

  //----------------------\\
  //*--- Switch Slide ---*\\
  //----------------------\\
  function checkIndex() {
    // enable touch action when transition ended
    setTouchAction(true);
    // remove transition className name for infinitive sliding
    setSmoothSliding(false);

    setClickAllowance(true);

    if (carousel) {
      if (activeSlideNo === imagesList.length - 2) {
        setActiveSlideNo(0);
      } else if (activeSlideNo === -1) {
        setActiveSlideNo(imagesList.length - 3);
      }
    }

    // to ignore click effect while sliding
    if (posDragEnd) {
      dispatch(setSliderSlidingState(false));
      setPosDragEnd(false);
    }
  }

  const switchSlide = (arg) => {
    // disable touch action until transition ends
    setTouchAction(false);
    // add transition className name for smooth sliding
    setSmoothSliding(true);
    if (clickAllowance) {
      // stop auto sliding during slide manually
      autoSliderFunc.stop();

      // if next or prev button clicked
      setClickAllowance(false);
      if (arg === "next") {
        setActiveSlideNo(activeSlideNo + 1);
      } else if (arg === "prev") {
        setActiveSlideNo(activeSlideNo - 1);
      }
      // start auto sliding again
      autoSliderFunc.start();
    }
  };

  //----------------------\\
  //*--- Touch Action ---*\\
  //----------------------\\
  const [posDragStart, setPosDragStart] = useState(false);
  const [posDragMove, setPosDragMove] = useState(false);
  const [posDragEnd, setPosDragEnd] = useState(false);

  useEffect(() => {
    if (Math.abs(posDragStart - posDragMove) > 3) {
      dispatch(setSliderSlidingState(true));
    }
  }, [posDragMove]);

  useEffect(() => {
    if (posDragEnd) {
      if (posDragStart - posDragMove === 0) {
        checkIndex();
        if (setPopOverSliderState) {
          setPopOverSliderState(true);
        }
      } else if (posDragStart - posDragMove > 50) {
        // setTimeout is used for sliding speed control, otherwise a slide moves more
        setTimeout(() => {
          switchSlide("next");
        }, 10);
      } else if (posDragStart - posDragMove < -50) {
        setTimeout(() => {
          switchSlide("prev");
        }, 10);
      } else {
        setSmoothSliding(true);
        if (carousel) {
          setSlideLeft(
            ((scrollableContentWidth * document.documentElement.clientWidth) /
              100) *
              (activeSlideNo + 1) *
              -1
          );
        } else {
          setSlideLeft(
            ((scrollableContentWidth * document.documentElement.clientWidth) /
              100) *
              activeSlideNo *
              -1
          );
        }
      }
    }
  }, [posDragEnd]);

  const dragStart = (e) => {
    // e.preventDefault();

    autoSliderFunc.stop();

    // if it is a laptop screen
    setPosDragStart(e.clientX);
    // We need to set this value at the beginning. Otherwise, the slide on the screen moves when the touch action started
    setPosDragMove(e.clientX);

    document.onmousemove = dragMove;
    document.onmouseup = dragEnd;
  };
  function dragMove(e) {
    // e.preventDefault();

    // disable touch action until transition ends
    setTouchAction(false);

    setPosDragMove(e.clientX);
  }
  function dragEnd() {
    setPosDragEnd(true);

    // stop auto sliding during slide manually
    autoSliderFunc.start();

    document.onmousemove = null;
    document.onmouseup = null;
  }

  const slidersElm = useRef(null);

  // useEffect(() => {
  //   if (Math.abs(posDragStart - posDragMove) > 30) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [posDragMove]);

  useEffect(() => {
    const element = slidersElm.current;
    element.addEventListener(
      "touchstart",
      (e) => {
        // e.preventDefault();

        autoSliderFunc.stop();

        // if it is a touch screen
        if (e.type === "touchstart") {
          // not a single touch
          if (e.touches.length === 2) {
            //   setTwoFingersTouched(true);
          } else {
            // e.preventDefault();
            //   setTwoFingersTouched(false);
          }
          // if (e.touches.length === 2) return;

          setPosDragStart(e.touches[0].clientX);
          setPosDragMove(e.touches[0].clientX);
        } else {
          // if it is a laptop screen
          setPosDragStart(e.clientX);
          // We need to set this value at the beginning. Otherwise, the slide on the screen moves when the touch action started
          setPosDragMove(e.clientX);
        }
        document.onmousemove = dragMove;
        document.onmouseup = dragEnd;
      },
      { passive: false }
    );
  }, []);

  useEffect(() => {
    const element = slidersElm.current;
    element.addEventListener(
      "touchmove",
      (e) => {
        // e.preventDefault();

        // disable touch action until transition ends
        setTouchAction(false);

        // not a single touch
        // if (e.touches.length === 2) {
        //   setTwoFingersTouched(true);
        // } else {
        //   setTwoFingersTouched(false);
        // }
        // if (e.touches.length === 2) return;

        setPosDragMove(e.touches[0].clientX);

        // if (
        //   posDragStart &&
        //   Math.abs(posDragStart - e.touches[0].clientX) > 50
        // ) {
        //   e.preventDefault();
        //   // document.body.style.overflow = "hidden";
        // } else {
        //   // document.body.style.overflow = "auto";
        // }
      },
      { passive: false }
    );
  }, [posDragStart]);

  useEffect(() => {
    const element = slidersElm.current;
    element.addEventListener(
      "touchend",
      (e) => {
        // e.preventDefault();

        setPosDragEnd(true);
        // stop auto sliding during slide manually
        autoSliderFunc.start();

        document.onmousemove = null;
        document.onmouseup = null;
      },
      { passive: false }
    );
  }, []);

  // move the current slide while sliding
  useEffect(() => {
    if (carousel) {
      setSlideLeft(
        containerWidth * (activeSlideNo + 1) * -1 - (posDragStart - posDragMove)
      );
    } else {
      setSlideLeft(
        containerWidth * activeSlideNo * -1 - (posDragStart - posDragMove)
      );
    }
  }, [posDragMove]);

  useEffect(() => {
    if (switchSlideTrigger) {
      switchSlide(switchSlideTrigger);
      setSwitchSlideTrigger(false);
    }
  }, [switchSlideTrigger]);

  return (
    <CategorySliderArea
      className={className}
      mainColor={mainColor}
      mainColor2={mainColor2}
      mainColor3={mainColor3}
      mainColor4={mainColor4}
      lightgray={lightgray}
      gray={gray}
      darkgray={darkgray}
      white={white}
      size={size}
      style={{
        width:
          widthType === "vw"
            ? `${scrollableContentWidth}vw`
            : `${scrollableContentWidth}px`,
        height:
          widthType === "vw"
            ? `${scrollableContentHeight}vw`
            : `${scrollableContentHeight}px`,
        // height: `${scrollableContentHeight}vw`,
      }}
    >
      {jobDivs}
      {dateDivs}

      <div
        /* cursor: cursor ? cursor : grab; */
        className={"slides"}
        style={{
          transition: smoothSliding ? "all 0.4s ease-in-out" : "",
          left: `${slideLeft}px`,
          pointerEvents: touchAction ? "auto" : "none",
          height:
            widthType === "vw"
              ? `${scrollableContentHeight}vw`
              : `${scrollableContentHeight}px`,
          // height: `${scrollableContentHeight}vw`,
          cursor: cursor ? cursor : "grab",
        }}
        onTransitionEnd={() => checkIndex()}
        onMouseDown={(e) => dragStart(e)}
        ref={slidersElm}
      >
        {imagesList.map((categoriesDiv, index) => (
          <div
            key={index.toString()}
            className="slide"
            style={{
              width: widthType === "vw" ? `${cardWidth}vw` : `${cardWidth}px`,
              // width: `${cardWidth}vw`,
              height:
                widthType === "vw"
                  ? `${scrollableContentHeight}vw`
                  : `${scrollableContentHeight}px`,
              // height: `${scrollableContentHeight}vw`,
            }}
          >
            {categoriesDiv}
          </div>
        ))}
      </div>

      {showNextPrevArrow && showHideNextBtn && (
        <NextPrevArrow
          className={"next"}
          next={true}
          switchSlide={switchSlide}
          size={size}
          bgColor={windowWidth < 1200 ? "rgba(0, 0, 0, 0.3)" : ""}
          fillColor={windowWidth < 1200 ? mainColor2 : gray}
          borderColor={windowWidth < 1200 ? mainColor2 : gray}
          borderThickness={windowWidth < 1200 ? "0" : borderThickness}
          hoverBgColor={mainColor3}
          hoverFillColor={mainColor2}
          transformDist={windowWidth < 1200 ? "0" : "-1rem"}
          opacity={windowWidth < 1200 ? "1" : "0"}
          rightDist={nextPrevLRDist}
        />
      )}

      {showNextPrevArrow && showHidePrevBtn && (
        <NextPrevArrow
          className={"prev"}
          next={false}
          switchSlide={switchSlide}
          size={size}
          bgColor={windowWidth < 1200 ? "rgba(0, 0, 0, 0.3)" : ""}
          fillColor={windowWidth < 1200 ? mainColor2 : gray}
          borderColor={windowWidth < 1200 ? mainColor2 : gray}
          borderThickness={windowWidth < 1200 ? "0" : borderThickness}
          hoverBgColor={mainColor3}
          hoverFillColor={mainColor2}
          transformDist={windowWidth < 1200 ? "0" : "1rem"}
          opacity={windowWidth < 1200 ? "1" : "0"}
          leftDist={nextPrevLRDist}
        />
      )}
    </CategorySliderArea>
  );
};

const CategorySliderArea = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  // this one and position: absolute in .slides are essential
  position: relative;
  /* touch-action: pan-y; */

  border-radius: 0.5vw;

  .slides {
    display: flex;
    position: absolute;

    .slide {
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .slideItem {
        /* width: 100%;
        height: 100%; */

        /* .image {
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
          }
        } */

        .video {
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          video {
            width: 100%;
          }
        }
      }

      .cardSlideItem {
        background-color: ${(props) => props.darkgray};
        width: 98%; // for create gap between sliders
        height: 100%;
        border-radius: 0.5vw;
        position: relative;
        overflow: hidden;
        /* cursor: pointer; */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */

        .headline {
          background-color: ${(props) => props.mainColor2};
          color: ${(props) => props.white};
          width: 100%;
          height: 10%;
          font-weight: 500;
          text-align: center;

          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        /* for product details link */
        a {
          background-color: ${(props) => props.white};
          width: 100%;
          height: 90%;
        }

        .imageArea {
          background-color: ${(props) => props.white};
          width: 100%;
          /* height: 15vw; */
          position: relative;
          overflow: hidden;

          display: flex;
          align-items: center;
          justify-content: center;

          .image {
            width: 97%;
            height: 95%;
            overflow: hidden;

            display: flex;
            align-items: center;
            justify-content: center;
            img {
              /* width: 100%; */
              height: 100%;
              /* object-fit: cover; */
              user-select: none;
            }
          }

          .crown {
            position: absolute;
            top: 0.2vw;
            left: 0.2vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        .infos {
          background-color: ${(props) => props.white};
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .name {
            /* background-color: gray; */
            width: 100%;
            /* height: 55%; */
            /* height: 6vw; */
            color: ${(props) => props.mainColor2};
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: ${(props) => props.darkgray};
              width: 90%;
              text-align: center;
              font-weight: 500;
            }
          }

          .price {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            gap: 1vw;

            position: absolute;
            bottom: 4vw;

            .discounted {
              color: ${(props) => props.darkgray};
            }

            .normal {
              display: flex;
              flex-direction: column;

              .discountLabel {
                font-weight: 600;
                color: ${(props) => props.mainColor};

                display: flex;
                align-items: center;
                span.arrow {
                  display: inline-block;
                  margin-right: 0.3vw;
                }
              }
              .realPrice {
                color: ${(props) => props.gray};
                text-decoration: line-through;
              }
            }
          }

          .category_products_btn {
            position: absolute;
            background-color: ${(props) => props.mainColor};
            width: 50%;
            height: 3vw;
            font-size: 1.5vw;
            padding: 0.6vw;
            font-weight: 600;
            color: ${(props) => props.white};
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            bottom: 1vw;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              font-size: 1.6vw;
            }

            &.active {
              opacity: 1;
              visibility: visible;
            }
          }

          .add_to_chart {
            background-color: ${(props) => props.mainColor};
            color: ${(props) => props.white};
            border-radius: 0.5vw;
            font-size: 1.3vw;
            font-weight: 400;
            cursor: pointer;

            position: absolute;
            bottom: 1vw;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              transition: all 0.3s ease-in-out;
              font-size: 1.35vw;
            }
          }
        }
      }

      /* cursor: cursor ? cursor : grab; */
    }
  }

  :hover {
    .prev,
    .next {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
`;

export default CategorySlider;
